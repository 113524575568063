import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
  theme: {
    themes: {
      light: {
        primary: "#26547c",
        secondary: "#d6e0f0",
        background: "#e4e4e4",
        accent: "#ffffff",
        error: "#E0144C",
        darkBackground: "#1B203D",
        dark: "#023047",
        alt: "#595da2",
        info: "#3C79AF",
        stepper: "#D4F5F3",
        success: "#1b998b",
        warning: "#fb8500",
      },
      dark: {
        primary: "#363636",
        secondary: "#d6e0f0",
        background: "#fff",
        accent: "#006a71",
        error: "#ff6b6b",
        info: "#2196F3",
        dark: "#363636",
        stepper: "#ebffeb",
        success: "#1b998b",
        warning: "#ffe66d",
      },
    },
  },
});
