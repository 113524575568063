<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card color="alt" class="dialog-header">
        <v-toolbar dark color="darkBackground">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة قسط جديد</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-autocomplete
            v-model="sectionId"
            outlined
            color="accent"
            label="اسم القسم"
            item-text="sectionName"
            item-value="idSection"
            :rules="studentRequiredFormRules"
            :items="$store.state.sections"
            item-color="accent"
          ></v-autocomplete>

          <v-autocomplete color="accent" v-model="level" :items="levels" :rules="studentRequiredFormRules" item-text="levelName" item-value="level" outlined label="المرحلة"></v-autocomplete>

          <v-autocomplete
            v-model="studyType"
            outlined
            color="accent"
            label="نوع الدراسة"
            item-text="typeName"
            item-value="studyId"
            :rules="studentRequiredFormRules"
            :items="studyTypes"
            item-color="accent"
          ></v-autocomplete>

          <vuetify-money
            v-model="amount"
            v-bind:label="label"
            :rules="studentRequiredFormRules"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="disabled"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text color="accent" @click="saveData" v-on:keyup.enter="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" icon small color="accent" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة قسط للقسم</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    sectionInstallments: Array,
  },
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,
    sectionId: "",
    studyType: "",
    levels: [
      { levelName: "المرحلة الاولى", level: 1 },
      { levelName: "المرحلة الثانية", level: 2 },
      { levelName: "المرحلة الثالثة", level: 3 },
      { levelName: "المرحلة الرابعة", level: 4 },
      { levelName: "المرحلة الخامسة", level: 5 },
    ],
    studyTypes: [
      { typeName: "صباحي", studyId: 1 },
      { typeName: "مسائي", studyId: 2 },
    ],
    level: "",

    amount: "0",
    label: "قيمة القسط",
    placeholder: " ",
    readonly: false,
    disabled: false,
    outlined: true,
    clearable: true,
    valueWhenIsEmpty: "",
    options: {
      locale: "pt-BR",
      prefix: "IQ",
      suffix: "",
      length: 11,
      precision: 0,
    },
  }),

  methods: {
    saveData() {
      let self = this;

      let currentDate = new Date();
      let foundYear = self.$store.state.years.find((year) => year.year.split("-")[0] == currentDate.getFullYear());

      if (73) {
        if (self.sectionId && self.amount && self.level) {
          let sectionCost = {
            sectionId: self.sectionId,
            amount: self.amount,
            level: self.level,
            studyYearId: 73,
            studyType: self.studyType,
          };

          let foundInstallments = self.sectionInstallments.find(
            (installment) =>
              installment.sectionId == sectionCost.sectionId &&
              installment.level == sectionCost.level &&
              installment.studyType == sectionCost.studyType &&
              installment.studyYearId == sectionCost.studyYearId
          );

          if (!foundInstallments) {
            this.$http
              .post(
                `${this.$store.state.apiUrlFees}/addSectionInstallments`,

                sectionCost,
                {
                  headers: { Authorization: `Bearer ${self.$store.state.user}` },
                }
              )
              .then((res) => {
                this.show = false;
                this.$toasted.success("تم اضافه البيانات");
                this.$emit("reloadTask");
              })
              .catch((err) => {
                this.$toasted.error(" حدث خطأ في البيانات");
              });
          } else {
            this.$toasted.error("المعلومات موجودة مسبقا");
          }
        } else {
          this.$toasted.error("الرجاء ادخال ادخال جميع القيم");
        }
      } else {
        console.log(foundYear);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
