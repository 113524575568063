<template>
  <div>
    <div style="text-align: center" class="not-printable">
      <v-btn @click="printReport()" tile color="primary">
        <v-icon left> mdi-printer </v-icon>
        طباعة
      </v-btn>
    </div>
    <div>
      <v-container class="container" fluid>
        <div>
          <h3 style="text-align: center; color: #fff">من تاريخ {{ dates[0] }} الى تاريخ {{ dates[1] }}</h3>
        </div>
        <table style="width: 100%; border: 1px solid" class="table-data" border="1">
          <tr>
            <th>التسلسل</th>
            <th>رقم الوصل</th>
            <th>تاريخ الوصل</th>
            <th colspan="2">نوع السند</th>
            <th>من صندوق</th>
            <th>المبلغ</th>
            <th colspan="4">الملاحظات</th>
          </tr>
          <tr v-for="(item, index) in receipt" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.idReceipt }}</td>
            <td>{{ item.receiptDateFormat }}</td>
            <td colspan="2">{{ item.receiptTypeId == 12 ? "قبض داخلي" : item.typeName }}</td>
            <td>{{ item.employeeName }}</td>
            <td>{{ item.amount }}</td>
            <td colspan="4">{{ item.notes }}</td>
          </tr>
        </table>
      </v-container>
    </div>
    <div>
      <p style="font-size: 8px">
        المجموع :
        {{
          getTotalMoney(receipt).toLocaleString("en-US", {
            style: "currency",
            currency: "IQD",
          })
        }}
      </p>
    </div>
  </div>
</template>

<script>
const Excel = require("exceljs");

export default {
  props: {
    receipt: Array,
    headers: Array,
    showExport: Boolean,
    dates: Array,
  },
  data() {
    return {
      tab: null,
      arrayData: [],
      voucherIndex: 0,
    };
  },
  created() {
    let self = this;
    let internalData = self.receipt.filter((data) => data.isInternal == 1);
    let externalData = self.receipt.filter((data) => data.isInternal == 2);
    let installment = internalData.filter((data) => data.boxId == 1);
    let identity = internalData.filter((data) => data.boxId == 2);
    let form = internalData.filter((data) => data.boxId == 7);
    let correct = internalData.filter((data) => data.boxId == 8);
    let previews = internalData.filter((data) => data.boxId == 5);
    let certificates = internalData.filter((data) => data.boxId == 4);
    let newData = [externalData, installment, identity, form, correct, previews, certificates];
    console.log(newData);
    self.arrayData = newData;
  },
  methods: {
    exportExcel() {
      let self = this;
      const workbook = new Excel.Workbook();

      let internalData = self.receipt.filter((data) => data.isInternal == 1);
      let externalData = self.receipt.filter((data) => data.isInternal == 2);
      let installment = internalData.filter((data) => data.boxId == 1);
      let identity = internalData.filter((data) => data.boxId == 2);
      let form = internalData.filter((data) => data.boxId == 7);
      let correct = internalData.filter((data) => data.boxId == 8);
      let previews = internalData.filter((data) => data.boxId == 5);
      let certificates = internalData.filter((data) => data.boxId == 4);
      let newData = [externalData, installment, identity, form, correct, previews, certificates];
      console.log(newData);

      newData.forEach((data, index) => {
        let name = self.getSheetName(index);
        const worksheet = workbook.addWorksheet(name);
        worksheet.views = [{ rightToLeft: true }];

        worksheet.columns = [
          { header: "رقم الوصل", key: "receiptNumber", width: 15 },
          { header: "تاريخ الوصل", key: "receiptDate", width: 50 },
          { header: "نوع السند", key: "type", width: 50 },
          { header: "المبلغ", key: "amount", width: 20 },
          { header: "الملاحظات", key: "notes", width: 50 },
        ];

        data.forEach((receipt) => {
          worksheet.addRow({
            receiptNumber: receipt.idReceipt,
            receiptDate: receipt.receiptDateFormat,
            type: receipt.isInternal == 1 ? "قبض داخلي" : receipt.typeName,
            amount: receipt.amount,
            notes: receipt.notes,
          });
        });

        worksheet.eachRow(function (row, rowNumber) {
          row.eachCell((cell) => {
            cell.alignment = { vertical: "middle", horizontal: "center" };
          });
        });

        worksheet.getRow(1).eachCell((cell) => {
          cell.font = { bold: true };
          cell.alignment = { vertical: "middle", horizontal: "center" };
          cell.fill = {
            type: "pattern",
            pattern: "darkTrellis",
            fgColor: { argb: "FFFFFF00" },
            bgColor: { argb: "FF0000FF" },
          };
        });
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = `report.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
    },
    printReport() {
      let self = this;
      const WinPrint = window.open("", "", "left=0,top=0,width=1000,height=900,toolbar=0,scrollbars=0,status=0");
      WinPrint.document.write(`<!DOCTYPE html>
        <!DOCTYPE html>
          
        <html lang="ar">
        <style>
         
        @media print {
          @page { margin: 0.5cm !important; }
          
          .pagebreak {
            clear: both;page-break-after: always;
          }
          table { page-break-inside:auto }
          tr    { page-break-inside:avoid; page-break-after:auto }
        }
          body {
            direction : rtl !important;
          }
          table {
            width : 98% !important;
          }
          tr,tr td,th{
            text-align : center !important;
            font-size : 12px !important;
            padding : 5px !important;
          }
        </style>
        <body>
              ${self.printTableData()}
              <h5 style="padding: 20px; font-size: 12px">
              المجموع الكلي :
              ${self.getTotalMoney(self.receipt).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })}
            </h5>
        </body>`);

      self.voucherIndex = 0;
      WinPrint.focus();
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },

    getTotalMoney(arrayOfData) {
      if (arrayOfData) {
        let total = arrayOfData.reduce((n, { amount }) => n + amount, 0);
        return total;
      } else {
        return 0;
      }
    },
    sliceArrayPrint(arrayOfData) {
      let begin = 0;
      let end = 20;
      let newArray = [];
      let numberOfIteration = Math.ceil(arrayOfData.length / 20);
      console.log(Math.ceil(arrayOfData.length / 20));

      for (let i = 0; i < numberOfIteration; i++) {
        newArray.push(arrayOfData.slice(begin, end * (i + 1)));
        begin += 20;
      }
      return newArray;
    },

    printTableData() {
      let self = this;
      let tableData = "";
      let receiptData = self.receipt;
      let dataPrint = self.sliceArrayPrint(receiptData.reverse());

      for (let i = 0; i < dataPrint.length; i++) {
        tableData += `
        <h4 style="text-align:center">${self.dates[0]} / ${self.dates[1]}</h4>
        <table border='1' style="padding:3px;margin: auto !important;">
            <tr>
                <th>التسلسل</th>
                <th>رقم الوصل</th>
                <th>تاريخ الوصل</th>
                <th>نوع السند</th>
                <th>من صندوق</th>
                <th>المبلغ</th>
                <th colspan="4">الملاحظات</th>
              </tr>
              ${self.rowTable(dataPrint[i])}
          </table>
          
          <h5 style="padding: 10px; font-size: 12px">
              المجموع :
              ${self.getTotalMoney(dataPrint[i]).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })}
            </h5>
            ${i == dataPrint.length - 1 ? "" : '<div style=" clear: both;page-break-after: always;"></div>'}
            
          `;
      }
      return tableData;
    },
    rowTable(arrayOfData) {
      let self = this;
      let tdData = "";
      for (let j = 0; j < arrayOfData.length; j++) {
        console.log(self.voucherIndex);
        self.voucherIndex += 1;
        tdData += `
              <tr>
                <td>${self.voucherIndex}</td>
                <td>${arrayOfData[j].idReceipt}</td>
                <td>${arrayOfData[j].receiptDateFormat}</td>
                <td>${arrayOfData[j].typeName}</td>
                <td>${arrayOfData[j].employeeName}</td>
                
                <td style="font-size:12px">${arrayOfData[j].amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })}</td>
                <td  colspan="4"> ${arrayOfData[j].notes}</td>
              </tr>
            
          `;
      }
      return tdData;
    },
    getSheetName(index) {
      switch (index) {
        case 0:
          return "المقبوضات الخارجية";
        case 1:
          return "الاقساط";
        case 2:
          return "الهويات";
        case 3:
          return "الاستمارات";
        case 4:
          return "صحة الصدور";
        case 5:
          return "السنوات السابقة";
        case 6:
          return "التأييدات والوثائق";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
tr,
tr td {
  text-align: center !important;
  padding: 5px !important;
}
tr,
tr td {
  text-align: center !important;
  color: #fff !important;
}
</style>
