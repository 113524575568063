<template>
  <div v-if="show">
    <div style="margin-top: 30px"></div>
    <v-card elevation="6" class="mx-auto radius-1 mb-10" style="text-align: center; padding: 20px" max-width="344" color="primary">
      <h3 style="color: #ffffff">الرئيسية</h3>
    </v-card>
    <v-row>
      <v-col cols="3">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
          <v-row>
            <v-col cols="4">
              <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
            </v-col>
            <v-col cols="8" class="division-title">
              <h5 class="warning-color" style="font-size: 1em; font-weight: bold">مجموع الصندوق الكلي</h5>
            </v-col>
          </v-row>
          <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
            {{
              getTotalMoney(main).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </h5>
        </v-card>
      </v-col>

      <v-col cols="3">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
          <v-row>
            <v-col cols="4">
              <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
            </v-col>
            <v-col cols="8" class="division-title">
              <h4 class="warning-color" style="font-size: 1em; font-weight: bold">الاقساط</h4>
            </v-col>
          </v-row>
          <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
            {{
              getTotalMoney(installment).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </h4>
        </v-card>
      </v-col>

      <v-col cols="3">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
          <v-row>
            <v-col cols="4">
              <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
            </v-col>
            <v-col cols="8" class="division-title">
              <h4 class="warning-color" style="font-size: 1em; font-weight: bold">الهويات</h4>
            </v-col>
          </v-row>
          <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
            {{
              getTotalMoney(identity).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </h4>
        </v-card>
      </v-col>

      <v-col cols="3">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
          <v-row>
            <v-col cols="4">
              <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
            </v-col>
            <v-col cols="8" class="division-title">
              <h4 class="warning-color" style="font-size: 1em; font-weight: bold">التأييدات والوثائق</h4>
            </v-col>
          </v-row>
          <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
            {{
              getTotalMoney(certificates).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </h4>
        </v-card>
      </v-col>

      <v-col cols="3">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
          <v-row>
            <v-col cols="4">
              <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
            </v-col>
            <v-col cols="8" class="division-title">
              <h4 class="warning-color" style="font-size: 1em; font-weight: bold">السنوات السابقة</h4>
            </v-col>
          </v-row>
          <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
            {{
              getTotalMoney(previous).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </h4>
        </v-card>
      </v-col>

      <v-col cols="3">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
          <v-row>
            <v-col cols="4">
              <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
            </v-col>
            <v-col cols="8" class="division-title">
              <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صحة الصدور</h4>
            </v-col>
          </v-row>
          <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
            {{
              getTotalMoney(correct).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </h4>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    payments: Array,
  },
  data() {
    return {
      main: 0,
      installment: 0,
      identity: 0,
      certificates: 0,
      previous: 0,
      correct: 0,
      show: false,
    };
  },
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      self.main = self.payments.filter((pay) => pay.paymentTypeId < 10 || pay.paymentTypeId == 34);
      self.installment = self.payments.filter((pay) => pay.paymentTypeId < 10);
      self.identity = self.payments.filter((pay) => pay.paymentTypeId == 24);
      self.certificates = self.payments.filter(
        (pay) =>
          pay.paymentTypeId == 10 ||
          pay.paymentTypeId == 11 ||
          pay.paymentTypeId == 21 ||
          pay.paymentTypeId == 22 ||
          pay.paymentTypeId == 23 ||
          pay.paymentTypeId == 23 ||
          pay.paymentTypeId == 25 ||
          pay.paymentTypeId == 26 ||
          pay.paymentTypeId == 27 ||
          pay.paymentTypeId == 28 ||
          pay.paymentTypeId == 29 ||
          pay.paymentTypeId == 30 ||
          pay.paymentTypeId == 35
      );
      self.previous = self.payments.filter((pay) => pay.paymentTypeId == 31);
      self.correct = self.payments.filter((pay) => pay.paymentTypeId == 34);
      self.show = true;

      // 10 , 11 ,21,22 ,23,25,26,27,28,29,30
      console.log(self.certificates);
    },
    getTotalMoney(arrayOfData) {
      if (arrayOfData) {
        let total = arrayOfData.reduce((n, { amount }) => n + amount, 0);
        return total;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
