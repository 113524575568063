<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="searchDialog" persistent max-width="700px" width="700px">
      <v-card color="alt" class="dialog-header">
        <v-toolbar dark color="darkBackground">
          <v-btn icon dark @click="searchDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>انشاء تقرير</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 20px"></div>
          <v-row>
            <v-col cols="6">
              <v-autocomplete color="accent" v-model="typeId" :items="reportType" item-text="name" item-value="id" outlined label="نوع التقرير"></v-autocomplete>
              <v-autocomplete color="accent" v-model="boxId" v-if="typeId == 2" :items="expensesSecondOptions" item-text="name" item-value="id" outlined label="الصندوق"></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-dialog ref="dialog" v-model="datePickerSearchModel" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field color="accent" v-model="dateRangeText" label="اختر التاريخ من - الى" readonly outlined v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="dates" scrollable range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="datePickerSearchModel = false"> الغاء </v-btn>
                  <v-btn text color="primary" @click="getDates"> موافق </v-btn>
                </v-date-picker>
              </v-dialog>
              <v-autocomplete
                color="accent"
                v-model="expensesTypeId"
                v-if="typeId == 2"
                :items="expensesTypes"
                item-text="typeName"
                item-value="idExpensesType"
                outlined
                label="التبويب"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-textarea color="accent" v-model="content" v-if="typeId == 2" outlinedlabel="تحتوي على" :rules="studentRequiredFormRules"></v-textarea>
          <v-autocomplete color="accent" v-model="receiptBoxId" v-if="typeId == 1" :items="receiptSecondOPtions" item-text="name" item-value="id" outlined label="الصندوق"></v-autocomplete>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="accent" text @click="searchData" v-on:keyup.enter="searchData"> بحث </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" icon small color="accent" @click="searchDialog = true" v-bind="attrs" v-on="on">
          <v-icon> mdi-magnify </v-icon>
        </v-btn>
      </template>
      <span>بحث</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    expensesTypes: Array,
  },
  data: () => ({
    searchDialog: false,
    typeId: "",
    expensesTypeId: "",
    boxId: "",
    receiptBoxId: "",
    content: "",
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    datePickerSearchModel: false,
    reportType: [
      { name: "ايرادات", id: 1 },
      { name: "مصروفات", id: 2 },
      { name: "التقرير اليومي للصناديق", id: 3 },
    ],
    receiptSecondOPtions: [
      { name: "الجميع", id: 999 },
      { name: "الصندوق الرئيسي", id: 1 },
      { name: "صندوق الهويات", id: 2 },
      { name: "صندوق الاستمارات", id: 7 },
      { name: "صندوق هويات حجز مقعد", id: 9 },
      { name: "صندوق اقساط حجز مقعد", id: 10 },
    ],
    expensesSecondOptions: [
      { name: "الجميع", id: 999 },
      { name: "الصندوق الرئيسي", id: 1 },
      { name: "الصندوق الرئيسي عدا الهويات", id: 3 },
      { name: "صندوق التأييدات", id: 4 },
      { name: "صندوق الاستمارات", id: 7 },
      { name: "صندوق الهويات", id: 2 },
      { name: "صندوق هويات حجز مقعد", id: 9 },
      { name: "صندوق اقساط حجز مقعد", id: 10 },
    ],
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    studentName: "",
    collegeNumber: "",
    overlay: false,
    levels: [
      { levelName: "المرحلة الاولى", level: 1 },
      { levelName: "المرحلة الثانية", level: 2 },
      { levelName: "المرحلة الثالثة", level: 3 },
      { levelName: "المرحلة الرابعة", level: 4 },
      { levelName: "المرحلة الخامسة", level: 5 },
    ],
    level: "",
    dates: [],
  }),
  created() {
    if (this.$store.state.userData.idEmployee == 223) {
      this.reportType = [{ name: "ايرادات", id: 1 }];
    }
  },
  methods: {
    searchData() {
      let self = this;
      self.overlay = true;
      if (self.typeId == 1 && self.dates.length > 0) {
        let query = "";

        if (self.receiptBoxId && self.receiptBoxId != 999) {
          query = `&boxId=${self.receiptBoxId}`;
        }

        console.log(self.receiptBoxId);
        self.$http
          .get(`${self.$store.state.apiUrlFees}/receiptsSearch?dates=${JSON.stringify(self.dates)}${query}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            self.overlay = false;
            console.log(res.data);
            let reportData = {
              data: res.data,
              type: self.typeId,
              dates: self.dates,
              headers: this.getHeaders(self.typeId),
            };
            this.$emit("clicked", reportData);
          })
          .catch((e) => {
            console.log(e);
            self.overlay = false;
          });
        this.searchDialog = false;
      } else if (self.typeId == 2 && self.dates.length > 0) {
        let query = "";

        if (self.expensesTypeId) {
          query += `&expensesTypeId=${self.expensesTypeId}`;
        }
        if (self.boxId) {
          query += `&boxId=${self.boxId}`;
        }

        if (self.content) {
          query += `&content=${self.content}`;
        }

        self.$http
          .get(`${self.$store.state.apiUrlFees}/expensesSearch?dates=${JSON.stringify(self.dates)}${query}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            self.overlay = false;
            console.log(res.data);
            let reportData = {
              data: res.data,
              type: self.typeId,
              dates: self.dates,
              headers: this.getHeaders(self.typeId),
            };
            this.$emit("clicked", reportData);
          })
          .catch((e) => {
            console.log(e);
            self.overlay = false;
          });
        this.searchDialog = false;
      } else if (self.typeId == 3 && self.dates.length > 0) {
        self.$http
          .all([
            self.$http.get(`${self.$store.state.apiUrlFees}/receiptsSearch?dates=${JSON.stringify(self.dates)}`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),
            self.$http.get(`${self.$store.state.apiUrlFees}/expensesSearch?dates=${JSON.stringify(self.dates)}`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),
          ])
          .then(
            self.$http.spread((receipt, expenses) => {
              let expensesData = expenses.data;
              let receiptData = receipt.data;
              let receiptFiltered = receiptData.filter((item) => item.boxId != 2 && item.boxId != 7 && item.boxId != 9);
              let expensesFiltered = expensesData.filter((item) => item.boxId != 2 && item.boxId != 7 && item.boxId != 9);

              let totalExpenses = expensesFiltered.reduce((n, { amount }) => n + amount, 0);
              let totalReceipt = receiptFiltered.reduce((n, { amount }) => n + amount, 0);

              let reportData = {
                data: expensesData,
                expenses: expensesFiltered,
                receipt: receiptFiltered,
                totalExpenses: totalExpenses,
                totalReceipt: totalReceipt,
                type: self.typeId,
                dates: self.dates,
              };
              this.$emit("clicked", reportData);
              self.overlay = false;
            })
          )
          .catch((e) => {
            console.log(e);
            self.overlay = false;
            this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
          });
        this.searchDialog = false;
      } else {
        this.$toasted.error("الرجاء اختيار المحددات");
        self.overlay = false;
      }
    },
    getHeaders() {
      let self = this;
      if (self.typeId == 1) {
        return [
          { text: "التسلسل", value: "index" },
          { text: "رقم الوصل", value: "idReceipt" },
          { text: "تاريخ الوصل", value: "receiptDateFormat" },
          { text: "نوع السند", value: "type" },
          { text: "المبلغ", value: "amount" },
          { text: "الملاحظات", value: "notes" },
        ];
      } else if (self.typeId == 2) {
        return [
          { text: "التسلسل", value: "index" },
          { text: "رقم الوصل", value: "idExpenses" },
          { text: "تاريخ الوصل", value: "expensesDateFormat" },
          { text: "نوع السند", value: "typeName" },
          { text: "المبلغ", value: "amount" },
          { text: "الملاحظات", value: "notes" },
          { text: "بواسطة", value: "employeeName" },
        ];
      } else {
      }
    },
    getDates() {
      this.datePickerSearchModel = false;
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.length > 0 ? this.dates.join(" - ") : "تاريخ من - الى";
    },
  },
};
</script>

<style lang="scss" scoped></style>
