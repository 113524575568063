<template>
  <v-app>
    <div class="logo-wrapper" v-if="$store.state.user != null">
      <svg id="logo-field" width="196" height="196" viewBox="0 0 196 196" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="98" cy="98" r="98" fill="#7E81CF" />
        <ellipse cx="98" cy="98" rx="85" ry="88" fill="#40426f" />
        <ellipse cx="99.5" cy="99" rx="62.5" ry="65" fill="white" />
      </svg>
    </div>

    <v-app-bar app color="#7E81CF" v-if="$store.state.user != null" extension-height="88">
      <template #extension>
        <v-toolbar color="#40426f" dark style="height: 88px">
          <div style="margin: auto">
            <v-list style="padding: 5px 30px 0px 0px" class="justify-space-around d-flex" dense color="#40426f">
              <v-list-item-group v-model="selectedItem" class="d-flex">
                <v-list-item v-for="(item, i) in items" :key="i" style="margin: 0 10px" color="white" @click="page(item)">
                  <v-list-item-icon>
                    <v-icon v-text="item.icon" color="white"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text" style="color: #ffffff"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-menu :offset-x="offset" min-width="400" max-width="300" v-if="$store.state.userData.roleId != 61">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-icon>
                        <v-icon color="white">mdi-menu</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title style="color: #ffffff">المزيد</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-list>
                    <v-list-item v-for="(item, index) in subList" :key="index" @click="page(item)">
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-list-item @click="logoutPage()" style="margin-right: 10%">
                  <v-list-item-icon style="padding: 0 0 0 3px">
                    <v-icon> mdi-account-circle-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h5 style="color: aliceblue">{{ $store.state.userData.employeeName }}</h5>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
          <div></div>
        </v-toolbar>
      </template>

      <div class="align-center" style="width: 100%; text-align: center">
        <div id="status"></div>
        <div style="text-align: center">
          <h3 style="color: #ffffff">{{ $store.state.pageName }}</h3>
        </div>
      </div>
    </v-app-bar>
    <!-- <v-navigation-drawer v-model="drawer" app class="pt-4 not-printable" color="primary" mini-variant fixed right v-if="$store.state.user != null">
      
      <v-list>
        <v-subheader></v-subheader>
        <v-list-item-group v-model="selectedItem" color="accent">
          <v-list-item v-for="(item, i) in items" :key="i" style="padding: 10px 15px" @click="page(item)">
            <v-tooltip left color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-text="item.icon" v-bind="attrs" v-on="on" color="white"></v-icon>
              </template>
              <span>{{ item.text }}</span>
            </v-tooltip>
          </v-list-item>

          <v-menu right :offset-x="offset" min-width="300" max-width="300" v-if="$store.state.userData.roleId != 61">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item style="padding: 10px 15px">
                <v-icon color="white" dark v-bind="attrs" v-on="on">mdi-backburger</v-icon>
              </v-list-item>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in subList" :key="index" @click="page(item)">
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-icon left color="white" @click="logoutPage()"> mdi-account-circle </v-icon>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="primary" v-if="$store.state.user != null" class="not-printable">
      <div class="d-flex align-center" style="width: 100%">
        <div style="text-align: right">
          <v-avatar color="secondary" size="50">
            <v-img :src="require('./assets/logo.png')" class="my-3" contain />
          </v-avatar>
        </div>
        <v-spacer></v-spacer>
        <h5 style="color: aliceblue">برنامج القسم المالي</h5>
        <v-spacer></v-spacer>
        <h5 @click="logoutPage()" style="color: aliceblue">{{ $store.state.userData.employeeName }}</h5>
      </div>
    </v-app-bar> -->

    <v-main class="center-print adjust-main">
      <div>
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    selectedItem: 0,
    drawer: true,
    subList: [],
    offset: true,
    login: false,
    items: [
      { title: "/", text: "الرئيسية", icon: "mdi-home" },
      {
        title: "/fees",
        text: "اقساط الطلبة",
        icon: "mdi-account-cash",
      },
      {
        title: "/transfer",
        text: "ترحيل الطلبة",
        icon: "mdi-account-arrow-up",
      },
      {
        title: "/add",
        text: "اضافة طالب",
        icon: "mdi-account-plus",
      },
      {
        title: "/one",
        text: "الطلبة الجدد",
        icon: "mdi-account-school",
      },
      // {
      //   title: "/sub",
      //   text: "الوصول الفرعية",
      //   icon: "mdi-receipt-text",
      // },
      {
        title: "/receipt",
        text: "الايرادات",
        icon: "mdi-inbox-arrow-down",
      },
      {
        title: "/expenses",
        text: "الصرفيات",
        icon: "mdi-inbox-arrow-up",
      },
      {
        title: "/form",
        text: "الاستمارات",
        icon: "mdi-form-select",
      },
      {
        title: "/cost",
        text: "اقساط الاقسام",
        icon: "mdi-cash-100",
      },

      { title: "/report", text: "تقارير الايرادات والمصروفات", icon: "mdi-file-chart" },

      { title: "/payment-report", text: "تقارير الطلبة", icon: "mdi-file-search" },
    ],
  }),
  created() {
    this.$store
      .dispatch("getUserDate")
      .then(() => {
        if (this.$store.state.user) {
          this.login = true;
          if (this.$store.state.userData.roleId != 61) {
            this.items = [
              { title: "/", text: "الرئيسية", icon: "mdi-home" },
              {
                title: "/fees",
                text: "اقساط الطلبة",
                icon: "mdi-account-cash",
              },
              {
                title: "/transfer",
                text: "ترحيل الطلبة",
                icon: "mdi-account-arrow-up",
              },
              {
                title: "/add",
                text: "اضافة طالب",
                icon: "mdi-account-plus",
              },
              // {
              //   title: "/one",
              //   text: "الطلبة الجدد",
              //   icon: "mdi-account-school",
              // },
              {
                title: "/paid",
                text: "تسديدات الطلبة",
                icon: "mdi-account-credit-card",
              },
              // {
              //   title: "/sub",
              //   text: "الوصول الفرعية",
              //   icon: "mdi-receipt-text",
              // },
              {
                title: "/receipt",
                text: "الايرادات",
                icon: "mdi-inbox-arrow-down",
              },
              {
                title: "/expenses",
                text: "الصرفيات",
                icon: "mdi-inbox-arrow-up",
              },
              {
                title: "/form",
                text: "الاستمارات",
                icon: "mdi-form-select",
              },

              { title: "/report", text: "تقارير الايرادات والمصروفات", icon: "mdi-file-chart" },

              { title: "/payment-report", text: "تقارير الطلبة", icon: "mdi-file-search" },
            ];
            this.subList = [
              {
                title: "/student-discount",
                text: "تقارير التخفيضات",
                icon: "mdi-chevron-triple-up",
              },
              {
                title: "/expenses-type",
                text: "تبوبات الصرف",
                icon: "mdi-chevron-triple-up",
              },
              {
                title: "/receipt-type",
                text: "تبويبات الايرادات",
                icon: "mdi-chevron-triple-down",
              },
              {
                title: "/accepted",
                text: "قنوات القبول",
                icon: "mdi-backburger",
              },
              {
                title: "/section-dates",
                text: "تقارير الاقسام حسب التاريخ",
                icon: "mdi-backburger",
              },
              {
                title: "/level-section",
                text: "احصائيات الاقسام والمراحل",
                icon: "mdi-backburger",
              },
              {
                title: "/cost",
                text: "اقساط الاقسام",
                icon: "mdi-cash-100",
              },
              // {
              //   title: "/custom",
              //   text: "طباعة جدول",
              //   icon: "mdi-cash-100",
              // },
            ];
          } else if (this.$store.state.userData.idEmployee == 223) {
            {
              this.items = [
                { title: "/", text: "الرئيسية", icon: "mdi-home" },
                {
                  title: "/fees",
                  text: "اقساط الطلبة",
                  icon: "mdi-account-cash",
                },
                {
                  title: "/transfer",
                  text: "ترحيل الطلبة",
                  icon: "mdi-account-arrow-up",
                },
                {
                  title: "/add",
                  text: "اضافة طالب",
                  icon: "mdi-account-plus",
                },

                {
                  title: "/one",
                  text: "الطلبة الجدد",
                  icon: "mdi-account-school",
                },
                // {
                //   title: "/sub",
                //   text: "الوصول الفرعية",
                //   icon: "mdi-receipt-text",
                // },

                {
                  title: "/form",
                  text: "الاستمارات",
                  icon: "mdi-form-select",
                },
                { title: "/report", text: "تقارير الايرادات والمصروفات", icon: "mdi-file-chart" },
                { title: "/payment-report", text: "تقارير الطلبة", icon: "mdi-file-search" },
              ];
            }
          } else {
            this.items = [
              { title: "/", text: "الرئيسية", icon: "mdi-home" },
              {
                title: "/fees",
                text: "اقساط الطلبة",
                icon: "mdi-account-cash",
              },
              {
                title: "/transfer",
                text: "ترحيل الطلبة",
                icon: "mdi-account-arrow-up",
              },
              {
                title: "/add",
                text: "اضافة طالب",
                icon: "mdi-account-plus",
              },
              {
                title: "/one",
                text: "الطلبة الجدد",
                icon: "mdi-account-school",
              },
              // {
              //   title: "/sub",
              //   text: "الوصول الفرعية",
              //   icon: "mdi-receipt-text",
              // },

              {
                title: "/form",
                text: "الاستمارات",
                icon: "mdi-form-select",
              },
              { title: "/payment-report", text: "تقارير الطلبة", icon: "mdi-file-search" },
            ];
          }

          this.$router.push("/").catch(() => {});
          this.initialization();
        } else {
          this.$router.push("/login").catch(() => {});
          this.login = false;
        }
      })
      .catch(() => {});
  },

  methods: {
    initialization() {
      let self = this;

      let date = new Date();
      let fullDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() - 1}`;

      // self.$http
      //   .get(`${self.$store.state.apiUrlFees}/getStatistics`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
      //   .then((res) => {
      //     console.log(res.data);
      //     self.$store.state.statistics = res.data;
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //     this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
      //   });

      // self.$http
      //   .get(`${self.$store.state.apiUrlFees}/boxAmountByDate/${fullDate}`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
      //   .then((res) => {
      //     console.log(res.data);
      //     self.$store.state.boxAmounts = res.data;
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //     this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
      //   });

      self.$http
        .get(`${self.$store.state.apiUrlRegistration}/sections`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
        .then((res) => {
          console.log(res.data);
          self.$store.state.sections = res.data;
        })
        .catch((e) => {
          console.log(e);
          this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });

      self.$http
        .get(`${self.$store.state.apiUrlRegistration}/yearStudies`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
        .then((res) => {
          console.log(res.data);
          self.$store.state.years = res.data;
        })
        .catch((e) => {
          console.log(e);
          this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });

      self.$http
        .get(`${self.$store.state.apiUrlRegistration}/acceptedTypes`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
        .then((res) => {
          console.log(res.data);
          self.$store.state.acceptedTypes = res.data;
        })
        .catch((e) => {
          console.log(e);
          this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });

      self.$http
        .get(`${self.$store.state.apiUrlFees}/paymentTypesNotInstallment`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
        .then((res) => {
          console.log(res.data);
          self.$store.state.paymentTypesNotInstallment = res.data;
        })
        .catch((e) => {
          console.log(e);
          this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });
    },
    logoutPage() {
      this.$router.push("/profile").catch(() => {});
    },
    page(item) {
      this.$router.push(item.title).catch(() => {});
    },
    logout() {
      localStorage.removeItem("employee");
      location.reload();
    },
  },
};
</script>
<style>
@import url("./font.css");
@media print {
  @page {
    size: A5 landscape !important;
    margin: 0cm;
  }
  @page :left {
    margin: 0cm;
  }

  @page :right {
    margin: 0cm;
  }
  .not-printable {
    display: none !important;
  }
  .not-printable {
    display: none !important;
  }
  body,
  html,
  main {
    -webkit-print-color-adjust: exact !important;
    height: 100% !important;
    width: 100% !important;
    padding: 0px !important;
    margin: 0px !important;
    color: #000;
    background-color: #fff !important;
  }
  .adjust-main {
    padding: 0px !important;
  }
  header nav,
  footer {
    display: none;
  }
  h3 {
    font-size: 12px !important;
  }
  h5 {
    font-size: 10px !important;
  }

  .center-print {
    background: #ffffff !important;
  }
  img {
    width: 230px !important;
    height: 50px !important;
  }
  .image-print {
    width: 120px !important;
    height: 120px !important;
    text-align: center !important;
    margin: auto !important;
  }
  span {
    font-size: 12px !important;
    font-weight: bolder !important;
  }
  .center-print {
    text-align: center !important;
  }
}
.warning-color,
.table-warning-color {
  color: #ffffff !important;
}
.table-warning-color {
  background-color: #40426f !important;
}
.table-warning-color tr th {
  color: #ffffff !important;
}
.menu-btn-profile {
  margin-top: 15px !important;
  padding: 35px 10px !important;
}
.menu-btn-profile span {
  display: block;
}
.menu-btn-profile-text {
  margin-bottom: 0px !important;
  padding-top: 10px;
  font-weight: 500;
  font-size: 12px;
}
.logo-wrapper {
  display: inline-block !important;
  z-index: 99;
  width: 200px;
  position: fixed;
}

.v-tab {
  justify-content: right !important;
  font-size: 16px !important;
  letter-spacing: 0 !important;
}

.v-tabs--vertical > .v-window {
  background-color: #40426e !important;
}
</style>
// "vue-cli-plugin-electron-builder": "git://github.com/spuky/vue-cli-plugin-electron-builder#b6826fc", //"productName": "تطبيق المالية",
