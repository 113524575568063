<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card color="alt" class="dialog-header">
        <v-toolbar dark color="darkBackground">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>تعديل معلومات القسط</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-autocomplete
            v-model="installment.sectionId"
            outlined
            color="accent"
            label="اسم القسم"
            item-text="sectionName"
            item-value="idSection"
            :rules="studentRequiredFormRules"
            :items="$store.state.sections"
            item-color="accent"
          ></v-autocomplete>

          <v-autocomplete
            color="accent"
            v-model="installment.level"
            :items="levels"
            :rules="studentRequiredFormRules"
            item-text="levelName"
            item-value="level"
            outlined
            label="المرحلة"
          ></v-autocomplete>

          <v-autocomplete
            v-model="installment.studyType"
            outlined
            color="accent"
            label="نوع الدراسة"
            item-text="typeName"
            item-value="studyId"
            :rules="studentRequiredFormRules"
            :items="studyTypes"
            item-color="accent"
          ></v-autocomplete>

          <vuetify-money
            v-model="installment.amount"
            v-bind:label="label"
            :rules="studentRequiredFormRules"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="disabled"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text color="accent" @click="saveData" v-on:keyup.enter="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon @click="show = true" v-bind="attrs" v-on="on">
          <v-icon color="accent"> mdi-pencil </v-icon>
        </v-btn>
      </template>
      <span>تعديل قسط للقسم</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    sectionInstallments: Array,
    installment: Object,
  },
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,

    levels: [
      { levelName: "المرحلة الاولى", level: 1 },
      { levelName: "المرحلة الثانية", level: 2 },
      { levelName: "المرحلة الثالثة", level: 3 },
      { levelName: "المرحلة الرابعة", level: 4 },
      { levelName: "المرحلة الخامسة", level: 5 },
    ],
    studyTypes: [
      { typeName: "صباحي", studyId: 1 },
      { typeName: "مسائي", studyId: 2 },
    ],

    amount: "0",
    label: "قيمة القسط",
    placeholder: " ",
    readonly: false,
    disabled: false,
    outlined: true,
    clearable: true,
    valueWhenIsEmpty: "",
    options: {
      locale: "pt-BR",
      prefix: "IQ",
      suffix: "",
      length: 11,
      precision: 0,
    },
  }),

  methods: {
    saveData() {
      let self = this;

      let currentDate = new Date();
      let foundYear = self.$store.state.years.find((year) => year.year.split("-")[0] == currentDate.getFullYear());

      if (73) {
        this.$http
          .put(
            `${this.$store.state.apiUrlFees}/sectionInstallments/${this.installment.idSectionInstallments}`,

            self.installment,
            {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }
          )
          .then((res) => {
            this.show = false;
            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          })
          .catch((err) => {
            this.$toasted.error(" حدث خطأ في البيانات");
          });
      } else {
        this.$toasted.error("الرجاء ادخال ادخال جميع القيم");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
