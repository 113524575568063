<template>
  <div>
    <div class="notPrintable">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-container class="container" fluid>
        <v-row>
          <v-col cols="3">
            <v-card color="alt" style="margin: 10px" dark>
              <v-list disabled>
                <div style="text-align: center">
                  <canvas id="barcode"></canvas>
                </div>

                <v-subheader>معلومات الطالب</v-subheader>
                <v-list-item-group color="accent">
                  <v-list-item>
                    <v-list-item-icon style="padding: 0 0 0 3px">
                      <v-icon> mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title> {{ $store.state.studentDetails.studentName }} </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon style="padding: 0 0 0 3px">
                      <v-icon> mdi-arrow-left-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title> قناة القبول : {{ $store.state.studentDetails.acceptedName }} </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon style="padding: 0 0 0 3px">
                      <v-icon> mdi-arrow-left-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>القسم : {{ $store.state.studentDetails.sectionName }} </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon style="padding: 0 0 0 3px">
                      <v-icon> mdi-arrow-left-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-if="$store.state.studentDetails.studentStatusId == 3">متخرج </v-list-item-title>
                      <v-list-item-title v-else>المرحلة : {{ $store.state.studentDetails.level | setLevel }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon style="padding: 0 0 0 3px">
                      <v-icon> mdi-arrow-left-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title> نوع الدراسة : {{ $store.state.studentDetails.studyType == 1 ? "مسائي" : "صباحي" }} </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon style="padding: 0 0 0 3px">
                      <v-icon> mdi-arrow-left-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>الرقم الجامعي : {{ $store.state.studentDetails.collegeNumber }} </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>

              <v-divider></v-divider>

              <v-list disabled color="#40426f">
                <v-subheader>المعلومات المالية</v-subheader>
                <v-list-item-group color="accent">
                  <v-list-item>
                    <v-list-item-icon style="padding: 0 0 0 3px">
                      <v-icon> mdi-arrow-left-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        >المبلغ الكلي :
                        {{
                          sectionInstallmentStudent.toLocaleString("en-US", {
                            style: "currency",
                            currency: "IQD",
                          })
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon style="padding: 0 0 0 3px">
                      <v-icon> mdi-arrow-left-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        المبلغ بعد التخفيض :
                        {{
                          getStudentLevelDiscountAmount($store.state.studentDetails.level, $store.state.studentDetails.yearStudyId) != undefined
                            ? (sectionCost - getStudentLevelDiscountAmount($store.state.studentDetails.level, $store.state.studentDetails.yearStudyId).amount * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            : sectionCost.toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                        }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon style="padding: 0 0 0 3px">
                      <v-icon> mdi-arrow-left-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>نسبة التسديد : {{ isNaN(parseInt((currentStudentPay / sectionCost) * 100)) ? 0 : parseInt((currentStudentPay / sectionCost) * 100) }}% </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon style="padding: 0 0 0 3px">
                      <v-icon> mdi-arrow-left-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        المسدد الفعلي :
                        {{
                          currentStudentPay.toLocaleString("en-US", {
                            style: "currency",
                            currency: "IQD",
                          })
                        }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon style="padding: 0 0 0 3px">
                      <v-icon> mdi-arrow-left-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>نسبة التخفيض : {{ $store.state.studentDetails.discount }} ٪</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon style="padding: 0 0 0 3px">
                      <v-icon> mdi-arrow-left-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        المتبقي :
                        {{
                          getStudentLevelDiscountAmount($store.state.studentDetails.level, $store.state.studentDetails.yearStudyId) != undefined
                            ? (
                                sectionCost -
                                currentStudentPay -
                                getStudentCircularAmount() -
                                getStudentLevelDiscountAmount($store.state.studentDetails.level, $store.state.studentDetails.yearStudyId).amount
                              ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            : (sectionCost - currentStudentPay - getStudentCircularAmount()).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                        }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon style="padding: 0 0 0 3px">
                      <v-icon> mdi-arrow-left-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title> حالة الطالب : {{ $store.state.studentDetails.statusName }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="profilePhotos.length > 0">
                    <v-list-item-icon style="padding: 0 0 0 3px">
                      <v-icon> mdi-arrow-left-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title><ShowStudentProfilePhotos style="display: inline-block; padding-top: 15px" :profilePhotos="profilePhotos" /></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>

              <v-divider></v-divider>

              <v-list color="#40426f">
                <v-subheader>الاجراءات</v-subheader>
                <v-list-item-group color="accent">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <AddStudentPay
                          :paymentTypesAll="paymentTypesAll"
                          :studentPay="$store.state.subFeeStudentDetails"
                          :sectionInstallment="sectionCost"
                          :remaining="sectionCost - currentStudentPay"
                          @reloadTask="initialData()"
                          style="padding: 10px; display: inline-block"
                        />
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <AddStudentProfilePhoto style="padding: 10px; display: inline-block" @reloadTask="initialData()" :collegeNumber="$store.state.studentDetails.collegeNumber" />
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title><EditAcceptedType style="padding: 10px; display: inline-block" @reloadTask="initialData()" :acceptedTypes="acceptedTypes" /> </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="9">
            <v-card v-for="(level, index) in allStudentFees" :key="index" style="margin: 20px 0">
              <div>
                <v-row v-if="$store.state.studentDetails.studentStatusId == 3">
                  <v-col>
                    <h5 style="padding: 20px; text-align: right; color: #fff">متخرج</h5>
                  </v-col>
                </v-row>

                <v-row v-else>
                  <v-col cols="4">
                    <h5 style="padding: 20px; text-align: right; color: #fff">{{ level[0].studentLevel | setLevel }}</h5>
                  </v-col>
                  <v-col cols="4">
                    <span v-if="studentDiscountAmount.length == 0 || getStudentLevelDiscountAmount(level[0].studentLevel, level[0].studyYearId) == undefined" style="text-align: center">
                      <div v-if="$store.state.userData.roleId != 61">
                        <AddStudentDiscountAmount :collegeNumber="level[0].collegeNumber" :yearStudyId="level[0].studyYearId" :studentLevel="level[0].studentLevel" @reloadTask="initialData()" />
                      </div>
                    </span>

                    <h5 v-else style="padding: 20px; text-align: center; color: #fff">
                      قيمة التخفيض :
                      {{
                        getStudentLevelDiscountAmount(level[0].studentLevel, level[0].studyYearId).amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })
                      }}

                      <ShowDiscountAmountNotes
                        style="display: inline-block"
                        :notes="getStudentLevelDiscountAmount(level[0].studentLevel, level[0].studyYearId).notes"
                        :imagePath="getStudentLevelDiscountAmount(level[0].studentLevel, level[0].studyYearId).imagePath"
                      />
                    </h5>
                  </v-col>
                  <v-col cols="4">
                    <h5 style="padding: 20px; text-align: left; color: #fff">{{ level[0].year }}</h5>
                  </v-col>
                </v-row>
              </div>

              <v-card color="#40426f" elevation="0">
                <v-card-title class="accent-color"> </v-card-title>
                <v-data-table :headers="headers" :items="level" :items-per-page="15" class="table-content table-warning-color">
                  <template v-slot:[`item.index`]="{ index }">
                    <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
                  </template>

                  <template v-slot:[`item.studentLevel`]="{ item }">
                    <p style="padding: 0 3px">{{ item.studentLevel | setLevel }}</p>
                  </template>

                  <template v-slot:[`item.sectionVoucher`]="{ item }">
                    <p style="padding: 0 3px">{{ $store.state.sections.find((section) => section.idSection == item.voucherSectionId).sectionName }}</p>
                  </template>

                  <template v-slot:[`item.amount`]="{ item }">
                    <h4 style="padding: 0 3px">
                      {{
                        item.amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })
                      }}
                    </h4>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn icon @click="printVoucher(item, level)">
                      <v-icon color="accent"> mdi-printer </v-icon>
                    </v-btn>
                    <div v-if="$store.state.userData.roleId != 61" style="display: inline-block">
                      <EditSectionIdVoucher :studentPay="item" />
                    </div>
                    <SoftDeleteStudentVoucher style="display: inline-block" :item="item" @reloadTask="initialData" v-if="$store.state.userData.roleId != 61" />
                  </template>
                </v-data-table>
                <h5 style="padding: 10px; background: #40426f; color: #fff">
                  المجموع :
                  {{
                    totalInstallmentPayed(level).toLocaleString("en-US", {
                      style: "currency",
                      currency: "IQD",
                    })
                  }}
                </h5>
              </v-card>

              <v-row style="margin: 0px; background: #40426f; color: #fff">
                <v-col cols="11">الملاحظات : {{ studentLevelNotes(level[0].studyYearId, level[0].collegeNumber) }}</v-col>

                <v-col cols="1" v-if="studentLevelNotes(level[0].studyYearId, level[0].collegeNumber) == 'لايوجد'" style="text-align: left">
                  <!-- <v-btn color="primary" fab x-small dark> <v-icon>mdi-plus</v-icon></v-btn> -->
                  <AddLevelNotes :collegeNumber="level[0].collegeNumber" :yearStudyId="level[0].studyYearId" @reloadTask="initialData()" />
                </v-col>

                <v-col cols="1" v-else style="text-align: left">
                  <EditLevelNotes :levelNote="studentLevelNotesId(level[0].studyYearId, level[0].collegeNumber)" @reloadTask="initialData()" />
                  <!-- <v-btn color="primary" fab x-small dark> <v-icon>mdi-pencil</v-icon></v-btn> -->
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="container" fluid v-if="!$store.state.studentDetails?.level">
        <v-card>
          <v-row>
            <v-col cols="3">
              <div style="padding: 10px">
                <h5 style="padding-top: 3px">
                  القسم :
                  {{ $store.state.studentDetails.sectionName }}
                </h5>
              </div>
            </v-col>
            <v-col cols="3">
              <div style="padding: 10px">
                <h5 style="padding-top: 3px">
                  نوع الدراسة :
                  {{ $store.state.studentDetails.studyType == 2 ? "مسائي" : "صباحي" }}
                </h5>
              </div>
            </v-col>
            <v-col cols="3">
              <h5 style="padding-top: 15px">الرقم الجامعي : {{ $store.state.studentDetails.collegeNumber }}</h5>
            </v-col>

            <v-col cols="3">
              <h5 style="padding-top: 15px">قيمة التخفيض : {{ $store.state.studentDetails.discount }} ٪</h5>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
import AddStudentPay from "../components/AddStudentPay.vue";
import SoftDeleteStudentVoucher from "../components/SoftDeleteStudentVoucher.vue";
import EditAcceptedType from "../components/EditAcceptedType.vue";
import AddLevelNotes from "../components/AddLevelNotes.vue";
import EditLevelNotes from "../components/EditLevelNotes.vue";
import AddStudentDiscountAmount from "../components/AddStudentDiscountAmount.vue";
import AddStudentProfilePhoto from "../components/AddStudentProfilePhoto.vue";
import ShowStudentProfilePhotos from "../components/ShowStudentProfilePhotos.vue";
import ShowDiscountAmountNotes from "../components/ShowDiscountAmountNotes.vue";
import EditSectionIdVoucher from "../components/EditSectionIdVoucher.vue";
import n2wordsAR from "n2words/lib/i18n/AR.mjs";
var JsBarcode = require("jsbarcode");

export default {
  components: {
    AddStudentPay,
    EditAcceptedType,
    SoftDeleteStudentVoucher,
    AddLevelNotes,
    EditLevelNotes,
    EditSectionIdVoucher,
    AddStudentDiscountAmount,
    ShowDiscountAmountNotes,
    AddStudentProfilePhoto,
    ShowStudentProfilePhotos,
  },
  data: () => ({
    haveDiscount: false,
    currentStudentPay: 0,
    amountPay: 0,
    sectionCost: 0,

    overlay: false,
    studentPayDetails: "",
    discountTypeId: "",
    discountStudent: "",
    paymentTypes: [],
    sectionInstallmentStudent: 0,
    allStudentFees: [],
    acceptedTypes: [],
    paymentTypesAll: [],
    levelNotes: [],
    studentDiscountAmount: [],
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "رقم الوصل", value: "voucherNumber" },
      { text: "تسديد عن", value: "typeName" },
      { text: "للمرحلة", value: "studentLevel" },
      { text: "تاريخ الوصل", value: "paymentDateFormat" },
      { text: "المبلغ", value: "amount" },
      { text: "قسم الوصل", value: "sectionVoucher" },
      {
        text: " الملاحظات",
        value: "notes",
      },
      { text: "الاجراءات", value: "actions" },
    ],
    studentDiscountPercentage: "",
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    studentPaymentsForAll: [],
    profilePhotos: [],
  }),
  created() {
    console.log(this.$store.state.studentDetails);
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      self.overlay = true;
      if (self.$store.state.studentDetails?.level) {
        self.$http
          .all([
            self.$http.get(`${self.$store.state.apiUrlFees}/studentPaymentsDetailsInstallment/${self.$store.state.studentDetails.collegeNumber}`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),
            self.$http.get(
              `${self.$store.state.apiUrlFees}/filterSectionInstallments?sectionId=${self.$store.state.studentDetails.sectionId}&studyYearId=73&level=${
                self.$store.state.studentDetails.level
              }&studyType=${self.$store.state.studentDetails.studyType == 0 ? 1 : 2}`,
              {
                headers: { Authorization: `Bearer ${self.$store.state.user}` },
              }
            ),

            self.$http.get(`${self.$store.state.apiUrlFees}/paymentTypesInstallment`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),

            self.$http.get(`${self.$store.state.apiUrlRegistration}/acceptedTypes`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),
            self.$http.get(`${self.$store.state.apiUrlFees}/${this.$store.state.studentDetails.studentStatusId == 3 ? "paymentTypesForNotContinue" : "paymentTypesForContinue"}`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),
            self.$http.get(`${self.$store.state.apiUrlRegistration}/levelNoteByStudentLevelId?collegeNumber=${self.$store.state.studentDetails.collegeNumber}`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),

            self.$http.get(
              `${self.$store.state.apiUrlFees}/levelStudentDiscountAmount?collegeNumber=${self.$store.state.studentDetails.collegeNumber}&studentLevel=${self.$store.state.studentDetails.level}`,
              {
                headers: { Authorization: `Bearer ${self.$store.state.user}` },
              }
            ),

            self.$http.get(`${self.$store.state.apiUrlFees}/studentProfilePhotos/${self.$store.state.studentDetails.collegeNumber}`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),
          ])
          .then(
            self.$http.spread((studentPayments, sectionInstallment, paymentTypes, acceptedTypes, allPaymentsType, notes, discountAmount, profilePhotos) => {
              self.profilePhotos = profilePhotos.data;
              self.studentPaymentsForAll = studentPayments.data.filter((pay) => pay.studyYearId == 73 && pay.paymentTypeId == 33);
              self.levelNotes = notes.data;
              let splitLevel = new Set(
                studentPayments.data.map((student) => {
                  return student.studyYearId;
                })
              );

              let filteredLevel = [];

              splitLevel.forEach((year) => {
                let yearData = studentPayments.data.filter((level) => level.studyYearId == year);
                filteredLevel.push(yearData);
              });

              self.allStudentFees = filteredLevel;
              self.studentDiscountAmount = discountAmount.data;

              let currentLevelPayment = studentPayments.data.filter((pay) => pay.studentLevel == self.$store.state.studentDetails.level && pay.paymentTypeId < 10 && pay.studyYearId == 73);
              self.currentStudentPay = currentLevelPayment.reduce((n, { amount }) => n + amount, 0);
              self.acceptedTypes = acceptedTypes.data;
              self.sectionCost = sectionInstallment.data[0].amount * ((100 - self.$store.state.studentDetails.discount) / 100);
              self.sectionInstallmentStudent = sectionInstallment.data[0].amount;
              self.discountStudent = sectionInstallment.data[0].discount;
              self.$store.state.paymentTypesInstallment = paymentTypes.data;
              self.paymentTypesAll = allPaymentsType.data;
              self.overlay = false;
              JsBarcode("#barcode", `${self.$store.state.studentDetails.collegeNumber}`, {
                width: 3,
                height: 30,
                displayValue: false,
              });
            })
          )
          .catch((error) => {
            self.overlay = false;
            console.log(error);
          });
      } else {
        let self = this;
        self.overlay = false;
        self.$http
          .all([
            self.$http.get(`${self.$store.state.apiUrlFees}/studentPaymentsDetailsInstallment/${self.$store.state.studentDetails.collegeNumber}`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),
            self.$http.get(`${self.$store.state.apiUrlFees}/paymentTypesInstallment`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),
            self.$http.get(`${self.$store.state.apiUrlRegistration}/acceptedTypes`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),
            self.$http.get(`${self.$store.state.apiUrlFees}/${this.$store.state.studentDetails.studentStatusId == 3 ? "paymentTypesForNotContinue" : "paymentTypesForContinue"}`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),
          ])
          .then(
            self.$http.spread((studentPayments, paymentTypes, acceptedTypes, allPaymentsType) => {
              self.studentPaymentsForAll = studentPayments.data.filter((pay) => pay.studyYearId == 73 && pay.paymentTypeId == 33);
              let splitLevel = new Set(
                studentPayments.data.map((student) => {
                  return student.studyYearId;
                })
              );
              console.log(splitLevel);
              let filteredLevel = [];
              splitLevel.forEach((year) => {
                let yearData = studentPayments.data.filter((level) => level.studyYearId == year);
                filteredLevel.push(yearData);
              });
              self.allStudentFees = filteredLevel;
              let currentLevelPayment = studentPayments.data.filter((pay) => pay.studentLevel == self.$store.state.studentDetails.level && pay.paymentTypeId < 10 && pay.studyYearId == 73);
              self.currentStudentPay = currentLevelPayment.reduce((n, { amount }) => n + amount, 0);
              self.acceptedTypes = acceptedTypes.data;
              self.$store.state.paymentTypesInstallment = paymentTypes.data;
              self.paymentTypesAll = allPaymentsType.data;
              self.overlay = false;
            })
          )
          .catch((error) => {
            self.overlay = false;
            console.log(error);
          });
      }
    },

    saveData() {
      let self = this;
      if (self.studentDiscountPercentage && self.discountTypeId) {
        let data = {
          studentId: self.$store.state.studentDetails.idStudent,
          studentName: self.$store.state.studentDetails.studentName,
          level: self.$store.state.studentDetails.studentLevel.level,
          sectionName: self.$store.state.studentDetails.sectionName,
          studyType: self.$store.state.studentDetails.studyType,
          acceptedTypeId: self.$store.state.studentDetails.acceptedType.idAcceptedType,
          acceptedTypeName: self.$store.state.studentDetails.acceptedType.acceptedName,
          discount: self.studentDiscountPercentage,
          discountTypeId: self.discountTypeId,
          createdBy: self.$store.state.userData.idUser,
        };
        self.$http.post(`${self.$store.state.apiUrlFees}/addStudentDiscount`, data).then((response) => {
          console.log(response);
          self.initialData();
        });
      } else {
        this.$toasted.error("الرجاء اختيار نوع التخفيض ونسبته ");
      }
    },

    getStudentLevelDiscountAmount(studentLevel, yearStudyId) {
      let self = this;
      let foundStudent = self.studentDiscountAmount.find((student) => student.studentLevel == studentLevel && student.yearStudyId == yearStudyId);
      return foundStudent;
    },

    getStudentCircularAmount() {
      let self = this;
      if (self.studentPaymentsForAll.length > 0) {
        return self.studentPaymentsForAll[0].amount;
      } else {
        return 0;
      }
    },

    studentLevelNotes(yearStudyId) {
      let self = this;
      if (self.levelNotes.length > 0) {
        let filteredData = self.levelNotes.find((note) => note.yearStudyId == yearStudyId);
        if (filteredData) {
          return filteredData.notes;
        } else {
          return "لايوجد";
        }
      } else {
        return "لايوجد";
      }
    },

    studentLevelNotesId(yearStudyId) {
      let self = this;
      if (self.levelNotes.length > 0) {
        let filteredData = self.levelNotes.find((note) => note.yearStudyId == yearStudyId);
        if (filteredData) {
          return filteredData;
        } else {
          return "لايوجد";
        }
      } else {
        return "لايوجد";
      }
    },

    getPercentage(paymentTypeId, arrayOfPayments, sectionCost) {
      if (paymentTypeId == 9) {
        return "100%";
      } else {
        let percentage = this.totalInstallmentPayed(arrayOfPayments) - sectionCost;
        return percentage;
      }
    },

    totalInstallmentPayed(arrayOfPayments) {
      if (arrayOfPayments.length > 0) {
        let filteredPayment = arrayOfPayments.filter((pay) => pay.paymentTypeId < 10);
        return filteredPayment.reduce((n, { amount }) => n + amount, 0);
      } else {
        return 0;
      }
    },

    printVoucher(voucher, arrayOfPayments) {
      let self = this;
      let stylesHtml = "";
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      let image = require("../assets/logo.png");

      // Open the print window
      const WinPrint = window.open("", "", "left=0,top=0,width=1000,height=900,toolbar=0,scrollbars=0,status=0");
      let numberWriting = n2wordsAR(voucher.amount, { lang: "ar" });
      let barcodeCanvas = document.getElementById("barcode");
      let currentDate = `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`;
      // let newStyle = stylesHtml.replaceAll("portrait", "landscape");
      // let lastStyle = newStyle.replaceAll("A4", "A5");

      //later
      //${this.$store.state.studentDetails.discount > 0? `<h5 data-v-4a284cfa="">بناءاً على قرار مجلس الكلية تقرر تخفيض نسبة ${this.$store.state.studentDetails.discount}% من القسط الاجمالي</h5>`: ""}

      console.log(this.$store.state.studentDetails);
      WinPrint.document.write(`<!DOCTYPE html>
      <!DOCTYPE html>
        <html lang="en">
        ${stylesHtml}

          <style>
          body ,html{ margin:0px !important}
          body{
            border:1px solid #eee !important;
          }
          @media print {
              @page {
                size: A5 landscape !important;
                margin: 0cm;
              }
              @page :left {
                margin: 0cm;
              }

              @page :right {
                margin: 0cm;
              }
              .not-printable {
                display: none !important;
              }
              body {
                -webkit-print-color-adjust: exact !important;
                height: 100% !important;
                width: 100% !important;
                padding: 0px !important;
                margin: 0px !important;
                color: #000;
                background-color: #fff !important;
                page-break-before: avoid;
                page-break-before: avoid;
              }
              .adjust-main {
                padding: 0px !important;
              }
              header nav,
              footer {
                display: none;
              }
              h3 {
                font-size: 11px !important;
              }
              h5 {
                font-size: 9px !important;
              }

              .center-print {
                background: #ffffff !important;
              }

              .image-print {
                width: 100px !important;
                height: 100px !important;
                text-align: center !important;
                margin: auto !important;
              }
              span {
                font-size: 10px !important;
                font-weight: bolder !important;
              }
              .center-print {
                text-align: center !important;
              }
            }
            img {
              padding: 5px;
            }
            .image-print {
              width: 80px !important;
              height: 80px !important;
              text-align: center !important;
              margin: auto !important;
            }
            span {
              font-size: 10px !important;
              font-weight: bolder !important;
            }

            .center-print {
              text-align: center !important;
              background: #ffffff !important;
            }
            .print-padding {
              padding: 5px !important;
            }
          </style>
          <body >
            <div data-v-4a284cfa="" class="center-print" style="border: 2px solid rgb(37, 37, 37); margin-top: 5px;height:100vh">
              <div data-v-4a284cfa="">
                <div data-v-4a284cfa="" class="row print-padding">
                  <div data-v-4a284cfa="" class="col col-4">
                    <h3 data-v-4a284cfa="">رقم الوصل : ${voucher.voucherNumber}</h3>
                    <h3 data-v-4a284cfa="">تاريخ الوصل : ${this.$store.state.studentDetails.studentStatusId == 4 ? voucher.paymentDateFormat : voucher.paymentDateFormat}</h3>

                  </div>
                  <div data-v-4a284cfa="" class="col col-4">
                    <div data-v-4a284cfa="" class="v-image v-responsive my-3 theme--light" style="height: 90px;margin:0px">
                      <div class="v-responsive__sizer" style="padding-bottom: 99.5851%"></div>
                      <div class="v-image__image v-image__image--contain" style="background-image: url('${image}'); background-position: center center"></div>
                      <div class="v-responsive__content" style="width: 241px"></div>
                    </div>
                  </div>
                  <div data-v-4a284cfa="" class="col col-4">
                    <h3 data-v-4a284cfa=""> ${self.sectionCost.toLocaleString("en-US", {
                      style: "currency",
                      currency: "IQD",
                    })} : اجور الدراسة </h3>
                    <h3 data-v-4a284cfa="" style="padding: 10px">نسية التحصيل الاجمالية : ٪${self.getPaidPercentage(voucher, arrayOfPayments)} </h3>
                  </div>
                </div>
                <div data-v-4a284cfa="">
                  <div data-v-4a284cfa="" class="row">
                    <div data-v-4a284cfa="" class="col col-4"></div>
                    <div data-v-4a284cfa="" class="col col-4">
                      <h3 data-v-4a284cfa="">كلية التجريب الاهلية / الحسابات</h3>
                      <h3 data-v-4a284cfa="">سند قبض</h3>
                      <div data-v-4a284cfa="">
                        <img src="${barcodeCanvas.toDataURL()}" />
                        <div style="display: none">Show this if the rendering fails.</div>
                      </div>
                    </div>
                    <div data-v-4a284cfa="" class="col col-4"></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">انا الموقع ادناه</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">عن</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">I Certify</h3></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <h3 data-v-4a284cfa="" style="padding: 10px">استلمت من الطالب / ة ${self.$store.state.studentDetails.studentName} / ذي الرقم الجامعي ${voucher.collegeNumber}</h3>
                  <h3 data-v-4a284cfa="" style="padding: 10px">( القسم : ${self.$store.state.studentDetails.sectionName} / ${this.$options.filters.setLevel(
        self.$store.state.studentDetails.level
      )} / الدراسة : ${self.$store.state.studentDetails.studyType == 1 ? "صباحي" : "مسائي"} )</h3>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">مبلغا وقدره</h3></div>
                    <div data-v-4a284cfa="" class="col col-4">
                      <h3 data-v-4a284cfa="" style="display: inline-block; margin-left: 10px">${voucher.amount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "IQD",
                      })}</h3>
                      <h3 data-v-4a284cfa="" style="display: inline-block">${numberWriting} فقط لاغير</h3>
                    </div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">Amount Of</h3></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">وذلك عن</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">${voucher.typeName}</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">For</h3></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">ملاحظات</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">${voucher.notes}</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">Notes</h3></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"></div>
                    <div data-v-4a284cfa="" class="col col-4"></div>
                    <div data-v-4a284cfa="" class="col col-4" style="padding-bottom: 10px">
                      <h3 data-v-4a284cfa="">اسم وتوقيع الموظف المختص</h3>
                      <h3 data-v-4a284cfa="">${voucher.employeeName}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </body>

        </html>
        `);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },
    getPaidPercentage(selected, allStudentPayment) {
      let self = this;

      let paid = 0;

      console.log(selected);

      if (selected.paymentTypeId == 1) {
        let allPaid = allStudentPayment.filter((pay) => pay.paymentTypeId == 1);
        paid = ((allPaid.reduce((n, { amount }) => n + amount, 0) * 1) / self.sectionCost) * 1 * 100;

        return Math.floor(paid);
      }

      if (selected.paymentTypeId == 3) {
        let allPaid = allStudentPayment.filter((pay) => pay.paymentTypeId == 1 || pay.paymentTypeId == 2 || pay.paymentTypeId == 3);
        paid = ((allPaid.reduce((n, { amount }) => n + amount, 0) * 1) / self.sectionCost) * 1 * 100;

        return Math.floor(paid);
      }

      if (selected.paymentTypeId == 4) {
        let allPaid = allStudentPayment.filter((pay) => pay.paymentTypeId == 1 || pay.paymentTypeId == 2 || pay.paymentTypeId == 3 || pay.paymentTypeId == 4);
        paid = ((allPaid.reduce((n, { amount }) => n + amount, 0) * 1) / self.sectionCost) * 1 * 100;
        return Math.floor(paid);
      }

      if (selected.paymentTypeId == 5) {
        let allPaid = allStudentPayment.filter((pay) => pay.paymentTypeId == 1 || pay.paymentTypeId == 2 || pay.paymentTypeId == 3 || pay.paymentTypeId == 4 || pay.paymentTypeId == 5);
        paid = ((allPaid.reduce((n, { amount }) => n + amount, 0) * 1) / self.sectionCost) * 1 * 100;

        return Math.floor(paid);
      }

      if (selected.paymentTypeId == 6) {
        let allPaid = allStudentPayment.filter(
          (pay) => pay.paymentTypeId == 1 || pay.paymentTypeId == 2 || pay.paymentTypeId == 3 || pay.paymentTypeId == 4 || pay.paymentTypeId == 5 || pay.paymentTypeId == 6
        );
        paid = ((allPaid.reduce((n, { amount }) => n + amount, 0) * 1) / self.sectionCost) * 1 * 100;

        return Math.floor(paid);
      }

      if (selected.paymentTypeId == 7) {
        let allPaid = allStudentPayment.filter(
          (pay) => pay.paymentTypeId == 1 || pay.paymentTypeId == 2 || pay.paymentTypeId == 3 || pay.paymentTypeId == 4 || pay.paymentTypeId == 5 || pay.paymentTypeId == 6 || pay.paymentTypeId == 7
        );
        paid = ((allPaid.reduce((n, { amount }) => n + amount, 0) * 1) / self.sectionCost) * 1 * 100;

        return Math.floor(paid);
      }

      if (selected.paymentTypeId == 8) {
        let allPaid = allStudentPayment.filter(
          (pay) =>
            pay.paymentTypeId == 1 ||
            pay.paymentTypeId == 2 ||
            pay.paymentTypeId == 3 ||
            pay.paymentTypeId == 4 ||
            pay.paymentTypeId == 5 ||
            pay.paymentTypeId == 6 ||
            pay.paymentTypeId == 7 ||
            pay.paymentTypeId == 8
        );
        paid = ((allPaid.reduce((n, { amount }) => n + amount, 0) * 1) / self.sectionCost) * 1 * 100;

        return Math.floor(paid);
      }

      if (selected.paymentTypeId == 9) {
        return 100;
      }

      let allPaid = allStudentPayment.filter(
        (pay) =>
          pay.paymentTypeId == 1 ||
          pay.paymentTypeId == 2 ||
          pay.paymentTypeId == 3 ||
          pay.paymentTypeId == 4 ||
          pay.paymentTypeId == 5 ||
          pay.paymentTypeId == 6 ||
          pay.paymentTypeId == 7 ||
          pay.paymentTypeId == 8 ||
          pay.paymentTypeId == 9
      );
      paid = ((allPaid.reduce((n, { amount }) => n + amount, 0) * 1) / self.sectionCost) * 1 * 100;

      return Math.floor(paid);
    },
  },

  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      } else {
        return "متخرج";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
