<template>
  <div>
    <v-container class="container not-printable" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>الطباعة</h3>
          </v-col>
          <v-col cols="2" align="left">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2" fab small color="primary" @click="$router.go(-1)" v-bind="attrs" v-on="on">
                  <v-icon dark> mdi-arrow-left </v-icon>
                </v-btn>
              </template>
              <span>رجوع</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <div style="border: 2px solid #252525; margin-top: 5px" class="center-print">
      <div>
        <v-row class="print-padding">
          <v-col cols="4">
            <h3>رقم الوصل : {{ voucher.idReceipt }}</h3>
            <h3>تاريخ الوصل : {{ voucher.receiptDateFormat }}</h3>
          </v-col>

          <v-col cols="4">
            <v-img :src="require('../assets/logo.png')" class="my-3" contain height="200" />
          </v-col>
          <v-col cols="4"> </v-col>
        </v-row>
        <div>
          <v-row>
            <v-col cols="4"></v-col>
            <v-col cols="4">
              <h3>كلية التجريب الاهلية / الحسابات</h3>
              <h3>سند قبض</h3>
              <!-- <img id="barcode" /> -->

              <VueBarcode :value="voucher.idReceipt" displayValue="false" width="3" height="50"> Show this if the rendering fails. </VueBarcode>
            </v-col>
            <v-col cols="4"></v-col>
          </v-row>
          <v-divider style="background: #000"></v-divider>
          <v-row class="print-padding">
            <v-col cols="4">
              <h3>انا الموقع ادناه</h3>
            </v-col>
            <v-col cols="4">
              <h3>امين الصندوق</h3>
            </v-col>
            <v-col cols="4">
              <h3>I Certify</h3>
            </v-col>
          </v-row>
          <v-divider style="background: #000"></v-divider>
          <h3 style="padding: 10px">استلمت من {{ voucher.isInternal == 1 ? voucher.fromEmployee : voucher.receiptTypeName }}</h3>

          <v-divider style="background: #000"></v-divider>
          <v-row class="print-padding">
            <v-col cols="4">
              <h3>مبلغا وقدره</h3>
            </v-col>
            <v-col cols="4">
              <h3 style="display: inline-block; margin-left: 10px">
                {{
                  voucher.amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h3>
              <h3 style="display: inline-block">{{ numberWriting }} فقط لاغير</h3>
            </v-col>
            <v-col cols="4"><h3>Amount Of</h3></v-col>
          </v-row>
          <v-divider style="background: #000"></v-divider>
          <v-row class="print-padding">
            <v-col cols="4">
              <h3>وذلك عن</h3>
            </v-col>
            <v-col cols="4">
              <h3>تسديد {{ voucher.idBox == 1 ? "اقساط" : voucher.boxName.split("صندوق")[1] }}</h3>
            </v-col>
            <v-col cols="4"><h3>For</h3></v-col>
          </v-row>
          <v-divider style="background: #000"></v-divider>
          <v-row class="print-padding">
            <v-col cols="4">
              <h3>ملاحظات</h3>
            </v-col>
            <v-col cols="4">
              <h3>{{ voucher.notes }}</h3>
            </v-col>
            <v-col cols="4"><h3>Notes</h3></v-col>
          </v-row>

          <v-divider style="background: #000"></v-divider>
          <v-row class="print-padding">
            <v-col cols="6"><h3>اسم وتوقيع الموظف المختص</h3></v-col>
            <v-col cols="6" style="padding-bottom: 80px">
              <h3>اسم وتوقيع امين الصندوق</h3>
            </v-col>
          </v-row>
        </div>
      </div>
      <button @click="print()" class="not-printable">print</button>
    </div>
  </div>
</template>

<script>
import n2wordsAR from "n2words/lib/i18n/AR.mjs";
var JsBarcode = require("jsbarcode");
import VueBarcode from "vue-barcode";

export default {
  components: {
    VueBarcode,
  },
  props: {
    invoice: String,
  },
  data() {
    return {
      voucher: null,
      show: false,
      numberWriting: "",
    };
  },
  created() {
    this.voucher = JSON.parse(this.invoice);
    console.log(this.voucher.collegeNumber);
    this.numberWriting = n2wordsAR(this.voucher.amount, { lang: "ar" });

    setTimeout(() => {
      JsBarcode("#barcode", `${this.voucher.collegeNumber}`, {
        width: 3,
        height: 30,
        displayValue: false,
      });
    }, 200);
    this.show = true;
  },
  methods: {
    print() {
      window.print();
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      } else {
        return "متخرج";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
