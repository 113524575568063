<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="searchDialog" persistent max-width="700px" width="700px">
      <v-card color="alt" class="dialog-header">
        <v-toolbar dark color="darkBackground">
          <v-btn icon dark @click="searchDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>بحث في الطلبة</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 20px"></div>
          <v-row>
            <v-col cols="6">
              <v-autocomplete color="accent" v-model="sectionId" :items="$store.state.sections" item-text="sectionName" item-value="idSection" label="القسم"></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete color="accent" v-model="level" :items="levels" item-text="levelName" item-value="level" label="المرحلة"></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="accent" text @click="searchData" v-on:keyup.enter="searchData"> بحث </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" icon small color="accent" @click="searchDialog = true" v-bind="attrs" v-on="on">
          <v-icon> mdi-magnify </v-icon>
        </v-btn>
      </template>
      <span>بحث</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data: () => ({
    searchDialog: false,
    sectionId: "",
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    studentName: "",
    collegeNumber: "",
    overlay: false,
    levels: [
      { levelName: "المرحلة الاولى", level: 1 },
      { levelName: "المرحلة الثانية", level: 2 },
      { levelName: "المرحلة الثالثة", level: 3 },
      { levelName: "المرحلة الرابعة", level: 4 },
      { levelName: "المرحلة الخامسة", level: 5 },
      { levelName: "متخرج", level: 10 },
    ],
    level: "",
  }),
  created() {},
  methods: {
    searchData() {
      let self = this;
      self.overlay = true;
      let query = " ";

      if (self.sectionId) {
        query += `&sectionId=${self.sectionId}`;
      }

      if (self.level != 10) {
        query += `&level=${self.level}`;
      }

      if (self.level == 10) {
        query += `&studentStatusId=3`;
      }

      if (self.studentName) {
        query += `&studentName=${self.studentName}`;
      }

      if (self.collegeNumber) {
        query += `&collegeNumber=${self.collegeNumber}`;
      }
      if (self.sectionId && self.level) {
        self.$http
          .get(`${self.$store.state.apiUrlRegistration}/getStudents?${query}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            self.overlay = false;
            this.$emit("clicked", res.data);
          })
          .catch((e) => {
            console.log(e);
            self.overlay = false;
          });
        this.searchDialog = false;
      } else {
        this.$toasted.error("الرجاء اختيار القسم والمرحلة ");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
