<template>
  <div style="margin-top: 0px">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-if="$store.state.userData.roleId != 61">
      <div v-if="$store.state.statistics != null">
        <v-container class="container" fluid>
          <v-card elevation="6">
            <v-toolbar flat color="primary" dark>
              <v-toolbar-title>الاحصائيات</v-toolbar-title>
            </v-toolbar>
            <v-tabs vertical>
              <v-tab class="white--text">
                <v-icon right> mdi-cash </v-icon>
                الارصدة الافتتاحية
              </v-tab>
              <v-tab class="white--text">
                <v-icon right> mdi-cash-multiple </v-icon>
                الارصدة
              </v-tab>
              <v-tab class="white--text">
                <v-icon right> mdi-cash-multiple </v-icon>
                الاحصائيات
              </v-tab>

              <v-tab class="white--text">
                <v-icon right> mdi-cash-multiple </v-icon>
                احصائيات المدفوعات للطلبة
              </v-tab>

              <v-tab class="white--text">
                <v-icon right> mdi-cash-multiple </v-icon>
                صناديق الايرادات
              </v-tab>

              <v-tab class="white--text">
                <v-icon right> mdi-cash-multiple </v-icon>
                صناديق ايرادات يومي
              </v-tab>

              <v-tab class="white--text">
                <v-icon right> mdi-cash-multiple </v-icon>
                صندوق الكاشير اليومي
              </v-tab>

              <v-tab class="white--text">
                <v-icon right> mdi-cash-multiple </v-icon>
                صرفيات الصناديق
              </v-tab>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 2em; color: #ffffff; margin-top: 20%">الصندوق الرئيسي</h3>
                          <h5 style="font-size: 2em; font-weight: lighter; color: #ffffff; margin: 25px">
                            {{
                              $store.state.boxAmounts.length > 0
                                ? $store.state.boxAmounts
                                    .find((box) => box.boxId == 1)
                                    .amount.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "IQD",
                                    })
                                : 0
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 2em; color: #ffffff; margin-top: 20%">الهويات</h3>
                          <h5 style="font-size: 2em; font-weight: lighter; color: #ffffff; margin: 25px">
                            {{
                              $store.state.boxAmounts.length > 0
                                ? $store.state.boxAmounts
                                    .find((box) => box.boxId == 2)
                                    .amount.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "IQD",
                                    })
                                : 0
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 2em; color: #ffffff; margin-top: 20%">الاستمارات</h3>
                          <h5 style="font-size: 2em; font-weight: lighter; color: #ffffff; margin: 25px">
                            {{
                              $store.state.boxAmounts.length > 0
                                ? $store.state.boxAmounts
                                    .find((box) => box.boxId == 7)
                                    .amount.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "IQD",
                                    })
                                : 0
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 2em; color: #ffffff; margin-top: 20%">اقساط حجز مقعد</h3>
                          <h5 style="font-size: 2em; font-weight: lighter; color: #ffffff; margin: 25px">
                            {{
                              $store.state.boxAmounts.length > 0
                                ? $store.state.boxAmounts
                                    .find((box) => box.boxId == 10)
                                    .amount.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "IQD",
                                    })
                                : 0
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center">
                        <div class="glass">
                          <h3 style="font-size: 2em; color: #ffffff; margin-top: 20%">هويات حجز مقعد</h3>
                          <h5 style="font-size: 2em; font-weight: lighter; color: #ffffff; margin: 25px">
                            {{
                              $store.state.boxAmounts.length > 0
                                ? $store.state.boxAmounts
                                    .find((box) => box.boxId == 9)
                                    .amount.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "IQD",
                                    })
                                : 0
                            }}
                          </h5>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 2em; color: #ffffff; margin-top: 20%">الصندوق الرئيسي</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              totalMain.toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 2em; color: #ffffff; margin-top: 20%">الهويات</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              totalIdentity.toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 2em; color: #ffffff; margin-top: 20%">الاستمارات</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              totalCertificate.toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 2em; color: #ffffff; margin-top: 20%">اقساط حجز مقعد</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              totalReservedBox.toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 2em; color: #ffffff; margin-top: 20%">هويات حجز مقعد</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              totalIdentityReservedBox.toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">الطلبة المستمرين للمراحل</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{ $store.state.statistics.studentLengthRestLevels }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">القسط الكلي للمراحل</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.amountAfterDiscountRestLevels - $store.state.statistics.repeatAmountRest - $store.state.statistics.discountAmountStudentsRest).toLocaleString(
                                "en-US",
                                {
                                  style: "currency",
                                  currency: "IQD",
                                }
                              )
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">القسط المدفوع للمراحل</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.paidRestLevels * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">المتبقي للمراحل</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.amountAfterDiscountRestLevels - $store.state.statistics.paidRestLevels).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">جميع طلبة المرحلة الاولى</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{ $store.state.statistics.studentLengthLevelOne }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">القسط الكلي للاول</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.amountAfterDiscountLevelOne - $store.state.statistics.repeatAmount - $store.state.statistics.discountAmountStudents).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">المدفوع للمرحلة الاولى</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.paidLevelOne * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">المتبقي للاول</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.amountAfterDiscountLevelOne - $store.state.statistics.paidLevelOne).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">مجموع المرحلة الاولى السابقين</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{ $store.state.studentsContinue.length }}
                          </h5>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row v-if="!overlay">
                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">مسددين الدفعة الاولى للمرحلة الاولى السابقين</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{ paymentStatistics.levelOneFirstPayment }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">مسددين الدفعة الثانية للمرحلة الاولى السابقين</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{ paymentStatistics.levelOneSecondPayment }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">مسددين الدفعة الثالثة للمرحلة الاولى السابقين</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{ paymentStatistics.levelOneThirdPayment }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">مسددين الدفعة الرابعة للمرحلة الاولى السابقين</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{ paymentStatistics.levelOneFourthPayment }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">مسددين كامل المبلغ للمرحلة الاولى السابقين</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{ paymentStatistics.levelOneFullPayment }}
                          </h5>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row v-if="!overlay">
                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">مسددين الدفعة الاولى للمرحلة الاولى</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{ paymentStatistics.levelOneNewFirstPayment }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">مسددين الدفعة الثانية للمرحلة الاولى</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{ paymentStatistics.levelOneNewSecondPayment }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">مسددين الدفعة الثالثة للمرحلة الاولى</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{ paymentStatistics.levelOneNewThirdPayment }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">مسددين الدفعة الرابعة للمرحلة الاولى</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{ paymentStatistics.levelOneNewFourthPayment }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">مسددين كامل المبلغ للمرحلة الاولى</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{ paymentStatistics.levelOneNewFullPayment }}
                          </h5>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row v-if="!overlay">
                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">مسددين الدفعة الاولى للمراحل</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{ paymentStatistics.levelsFirstPayment }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">مسددين الدفعة الثانية للمراحل</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{ paymentStatistics.levelsSecondPayment }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">مسددين الدفعة الثالثة للمراحل</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{ paymentStatistics.levelsThirdPayment }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">مسددين الدفعة الرابعة للمراحل</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{ paymentStatistics.levelsFourthPayment }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">مسددين كامل المبلغ للمراحل</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{ paymentStatistics.levelsFullPayment }}
                          </h5>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">الصندوق الرئيسي</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.mainBox * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">الهويات مركزي</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.identityBox * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">صحة صدور مركزي</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.correctBox * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">الوثائق والتأييدات مركزي</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.certificateBox * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">سنوات سابقة مركزي</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.previousYears * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">صندوق الاقساط اليومي</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.dailyInstallmentBox * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">صندوق الهويات اليومي</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.identityBox * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">صندوق صحة الصدور يومي</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.dailyCorrectBox * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">صندوق الوثائق والتأييدات يومي</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.dailyCertificateBox * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">صندوق استمارات يومي</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.dailyForm * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">علي مهدي</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.aliMahdi * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">عبد الرحمن مجدي</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.abdulrahman * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">نور سعد</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.noorSaad * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">موج واثق</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.moaj * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">ضمياء باسم</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.damyaa * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">صرفيات الصندوق الرئيسي</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.expensesAmount * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">صرفيات صندوق الهويات</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.expensesIdentityAmount * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">صرفيات الاستمارات</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.documentIdentityAmount * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">صرفيات هويات حجز مقعد</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.expensesReservedIdentity * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>

                      <v-col cols="3" style="text-align: center" align-self="center">
                        <div class="glass">
                          <h3 style="font-size: 1.3em; color: #ffffff; margin-top: 20%">صرفيات اقساط حجز مقعد</h3>
                          <h5 style="position: relative; top: 20px; font-size: 2em; font-weight: lighter; color: #ffffff">
                            {{
                              ($store.state.statistics.expensesInstallmentReserved * 1).toLocaleString("en-US", {
                                style: "currency",
                                currency: "IQD",
                              })
                            }}
                          </h5>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-container>
      </div>
    </div>
    <div v-if="$store.state.userData.roleId == 61">
      <EmployeeHomeStatistics :payments="payments" v-if="!overlay" />
    </div>
  </div>
</template>

<script>
import SectionChart from "./../components/SectionChart.vue";
import CategoryChart from "./../components/CategoryChart.vue";
import EmployeeHomeStatistics from "./../components/EmployeeHomeStatistics.vue";

export default {
  components: {
    SectionChart,
    CategoryChart,
    EmployeeHomeStatistics,
  },
  name: "Home",
  data: () => ({
    overlay: false,
    payments: [],
    advertisements: 0,
    sections: 0,
    categories: 0,
    totalIdentityReservedBox: 0,
    totalMain: 0,
    totalReservedBox: 0,
    totalIdentity: 0,
    totalCertificate: 0,
    paymentStatistics: null,
  }),

  created() {
    if (this.$store.state.userData.roleId != 61) {
      this.initialization();
    } else {
      this.employeeStatistics();
    }
  },
  methods: {
    getDateXDaysAgo(numOfDays, date = new Date()) {
      const daysAgo = new Date(date.getTime());
      daysAgo.setDate(date.getDate() - numOfDays);
      console.log(daysAgo.toISOString().split("T")[0]);

      return daysAgo.toISOString().split("T")[0];
    },
    initialization() {
      let self = this;
      self.overlay = true;
      let date = new Date();
      let fullDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      console.log(JSON.stringify([fullDate, fullDate]));
      // console.log(self.getDateXDaysAgo(1, new Date()));
      console.log(fullDate);
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlFees}/receiptsSearch?dates=${JSON.stringify([fullDate, fullDate])}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlFees}/expensesSearch?dates=${JSON.stringify([fullDate, fullDate])}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlFees}/getStatistics?tody=${fullDate}`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlFees}/boxAmountByDate/${self.getDateXDaysAgo(1, new Date())}`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/studentsContinue`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlFees}/getPaymentStatistics`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
        ])
        .then(
          self.$http.spread((receipts, expenses, statistics, boxAmounts, studentsContinue, paymentStatistics) => {
            console.log(paymentStatistics.data);
            self.paymentStatistics = paymentStatistics.data;
            self.$store.state.boxAmounts = boxAmounts.data;
            self.$store.state.statistics = statistics.data;
            self.$store.state.studentsContinue = studentsContinue.data;
            let expensesMainBox = expenses.data.filter((expense) => expense.boxId != 2 && expense.boxId != 7 && expense.boxId != 9);
            let expensesCertificateBox = expenses.data.filter((expense) => expense.boxId == 7);
            let expensesIdentityBox = expenses.data.filter((expense) => expense.boxId == 2);

            let reservedBoxExpenses = expenses.data.filter((expense) => expense.boxId == 10);
            let reservedBoxReceipt = receipts.data.filter((receipt) => receipt.boxId == 10);

            let identityReservedBoxExpenses = expenses.data.filter((expense) => expense.boxId == 9);
            let identityReservedBoxReceipt = receipts.data.filter((receipt) => receipt.boxId == 9);

            let receiptMainBox = receipts.data.filter((receipt) => receipt.boxId != 2 && receipt.boxId != 7 && receipt.boxId != 9);
            let receiptCertificateBox = receipts.data.filter((receipt) => receipt.boxId == 7);
            let receiptIdentityBox = receipts.data.filter((receipt) => receipt.boxId == 2);
            console.log(statistics);
            if (self.$store.state.boxAmounts.length > 0) {
              let totalMain =
                receiptMainBox.reduce((n, { amount }) => n + amount, 0) + self.$store.state.boxAmounts.find((box) => box.boxId == 1).amount - expensesMainBox.reduce((n, { amount }) => n + amount, 0);
              console.log(totalMain);
              let totalIdentity =
                receiptIdentityBox.reduce((n, { amount }) => n + amount, 0) +
                self.$store.state.boxAmounts.find((box) => box.boxId == 2).amount -
                expensesIdentityBox.reduce((n, { amount }) => n + amount, 0);
              let totalCertificate =
                receiptCertificateBox.reduce((n, { amount }) => n + amount, 0) +
                self.$store.state.boxAmounts.find((box) => box.boxId == 7).amount -
                expensesCertificateBox.reduce((n, { amount }) => n + amount, 0);
              let totalReservedBox =
                reservedBoxReceipt.reduce((n, { amount }) => n + amount, 0) +
                self.$store.state.boxAmounts.find((box) => box.boxId == 10).amount -
                reservedBoxExpenses.reduce((n, { amount }) => n + amount, 0);

              let totalIdentityReservedBox =
                identityReservedBoxReceipt.reduce((n, { amount }) => n + amount, 0) +
                self.$store.state.boxAmounts.find((box) => box.boxId == 9).amount -
                identityReservedBoxExpenses.reduce((n, { amount }) => n + amount, 0);
              console.log(self.totalReservedBox);

              self.totalReservedBox = totalReservedBox;
              self.totalIdentityReservedBox = totalIdentityReservedBox;
              self.totalCertificate = totalCertificate;
              self.totalIdentity = totalIdentity;
              self.totalMain = totalMain;
            }

            self.overlay = false;
          })
        )
        .catch((e) => {
          console.log(e);
          self.overlay = false;
          this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });
    },
    employeeStatistics() {
      let self = this;
      self.overlay = true;

      self.$http
        .get(`${self.$store.state.apiUrlFees}/studentPaymentEmployee/${self.$store.state.userData.idEmployee}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          self.payments = res.data;
          self.overlay = false;
          console.log(res.data);
        })
        .catch((e) => {
          self.overlay = false;
          console.log(e);
        });
    },
  },
};
</script>
<style scoped>
.circle-div > * {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}

.glass {
  height: 200px;
  width: 320px;

  margin: auto;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.175) 0%, rgba(255, 255, 255, 0.37) 100%) !important;
  /* background: rgba(255, 255, 255, 0.27); */
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.8px);
  -webkit-backdrop-filter: blur(3.8px);
  border: 1px solid rgba(255, 255, 255, 1);
}
</style>
