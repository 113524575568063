import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // apiUrl: "http://localhost:4320/api",
    // apiUrlFees: "http://localhost:4310/api",
    // apiUrlRegistration: "http://localhost:4300/api",
    // apiUrlApplication: "http://localhost:4340/api",
    apiUrl: "https://employee.mamoon.click/api",
    apiUrlApplication: "https://form.keynode.co.uk/api",
    apiUrlFees: "https://fees.mamoon.click/api",
    apiUrlRegistration: "https://mstudent.mamoon.click/api",
    sections: null,
    years: null,
    user: null,
    studentsContinue: 0,
    userData: null,
    paymentTypesNotInstallment: [],
    paymentTypesInstallment: [],
    paymentTypesAll: [],
    boxAmounts: [],
    studentPay: {},
    acceptedTypes: null,
    printInformation: "",
    studentsStatus: [
      { idStudentStatus: 1, statusName: "مستمر" },
      { idStudentStatus: 2, statusName: "محجوب" },
      { idStudentStatus: 3, statusName: "متخرج" },
      { idStudentStatus: 4, statusName: "طالب جديد" },
      { idStudentStatus: 5, statusName: "خروج بامر اداري" },
      { idStudentStatus: 6, statusName: "مؤجل" },
      { idStudentStatus: 7, statusName: "غير فعال" },
      { idStudentStatus: 8, statusName: "فعال" },
      { idStudentStatus: 9, statusName: "غير ملتحق بالدراسة" },
    ],
    levels: [
      { levelName: "المرحلة الاولى", level: 1 },
      { levelName: "المرحلة الثانية", level: 2 },
      { levelName: "المرحلة الثالثة", level: 3 },
      { levelName: "المرحلة الرابعة", level: 4 },
      { levelName: "المرحلة الخامسة", level: 5 },
    ],
    reportFees: "",
    studentDetails: null,
    subFeeStudentDetails: null,
    statistics: null,
  },
  mutations: {
    GET_USER_DATA(state, userData) {
      state.user = userData;
    },
  },
  actions: {
    getUserDate({ commit }) {
      let data = localStorage.getItem("employee");

      if (data) {
        var base64Url = data.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        let text = JSON.parse(jsonPayload);
        console.log(text);
        this.state.userData = text;
      }
      commit("GET_USER_DATA", JSON.parse(data));
    },
  },
  modules: {},
});
