<template>
  <div>
    <v-container class="container" fluid>
      <v-card color="#40426f" elevation="0">
        <v-card-title class="accent-color">
          <v-row>
            <v-col cols="4" style="line-height: 50px">
              <h4 class="white-text" style="vertical-align: middle">الطلبة الجدد</h4>
            </v-col>
            <v-col cols="4" style="line-height: 50px">
              <v-text-field v-model="search" color="accent" @change="searchStudent()" v-on:keyup.enter="searchStudent()" append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
            </v-col>
            <v-col cols="4" style="line-height: 50px; text-align: left">
              <SearchStudent style="display: inline-block" @clicked="onSearch" />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table :headers="headers" :items="students" :items-per-page="15" class="table-content table-warning-color">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>

          <template v-slot:[`item.level`]="{ item }">
            <p style="padding: 0 3px" v-if="item.studentStatusId == 3">متخرج</p>
            <p style="padding: 0 3px" v-else>
              {{ item.level | setLevel }}
            </p>
          </template>

          <template v-slot:[`item.studentName`]="{ item }">
            <v-btn text @click="selectStudent(item)" color="accent"> {{ item.studentName }} </v-btn>
          </template>

          <template v-slot:[`item.studyType`]="{ item }">
            <p style="padding: 0 3px">
              {{ item.studyType | setStudyType }}
            </p>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import SearchStudent from "../components/SearchStudent.vue";
export default {
  components: {
    SearchStudent,
  },
  data: () => ({
    search: "",
    selected: [],
    students: [],
    dataMessage: "الرجاء البحث عن طلبة",
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "اسم الطالب", value: "studentName" },
      { text: "رقم الاستمارة", value: "applicationNumber" },
      { text: "القسم", value: "firstSectionName" },
      { text: "الرقم الجامعي", value: "collegeNumber" },
      { text: "قناة القبول", value: "acceptedName" },
      { text: "الفرع", value: "categoryName" },
      {
        text: "نوع الدراسة",
        value: "firstStudyType",
      },
      //   { text: "الاجراءات", value: "actions" },
    ],
  }),
  created() {},
  methods: {
    onSearch(value) {
      let self = this;
      if (value.length > 0) {
        console.log(value);
        self.students = value;
      } else {
        self.dataMessage = "لايوجد طلبة في القسم";
      }
    },
    searchStudent() {
      let self = this;

      console.log(this.search);
      if (self.search) {
        self.$http
          .get(`${self.$store.state.apiUrlApplication}/applicationSearchForFinance/${self.search}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            self.overlay = false;
            self.students = res.data;
            console.log(res.data);
          })
          .catch((e) => {
            console.log(e);
            self.overlay = false;
          });
      }
    },
    addFess() {
      console.log(this.selected);
      if (this.selected.length > 0) {
        this.$store.state.studentPay = this.selected[0];
        this.$router.push("/fees").catch((error) => {});
      } else {
        this.$toasted.error("الرجاء اختيار طالب ");
      }
    },
    selectStudent(student) {
      let self = this;
      self.$store.state.studentLevelOne = student;
      this.$router.push("/one-details").catch((error) => {});
      console.log(student);
    },
    removeData() {
      let self = this;

      self.selected = [];
      self.students = [];
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      }
    },
    setStudyType: function (studyType) {
      if (studyType == 1) {
        return "صباحي";
      } else {
        return "مسائي";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
