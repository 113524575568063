<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container class="container" fluid>
      <v-card color="#40426f" elevation="0">
        <v-card-title class="accent-color">
          <v-row>
            <v-col cols="4" style="line-height: 50px">
              <h4 class="white-text" style="vertical-align: middle">تبويب الايرادات</h4>
            </v-col>
            <v-col cols="4" style="line-height: 50px">
              <v-text-field v-model="search" color="accent" @change="searchStudent()" v-on:keyup.enter="searchStudent()" append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
            </v-col>
            <v-col cols="4" style="line-height: 50px; text-align: left">
              <AddReceiptType style="display: inline-block" @reloadTask="initialData" v-if="!overlay" />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table :headers="headers" :items="sectionInstallments" :items-per-page="15" class="table-content table-warning-color">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>

          <template v-slot:[`item.level`]="{ item }">
            <p style="padding: 0 3px">{{ item.level | setLevel }}</p>
          </template>

          <template v-slot:[`item.studyType`]="{ item }">
            <p style="padding: 0 3px">{{ item.studyType | setStudyType }}</p>
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            <p style="padding: 0 3px">
              {{
                item.amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })
              }}
            </p>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="dialog = false">
              <v-icon @click="deleteItem(item)" color="accent"> mdi-delete </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AddReceiptType from "../components/AddReceiptType.vue";
export default {
  components: {
    AddReceiptType,
  },
  data: () => ({
    search: "",
    overlay: false,
    selected: [],
    sectionInstallments: [],
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "اسم التبويب", value: "typeName" },
      { text: "الاجراءات", value: "actions" },
    ],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      self.overlay = true;
      self.$http
        .get(`${self.$store.state.apiUrlFees}/receiptTypes`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          self.sectionInstallments = res.data;
          self.overlay = false;
        })
        .catch((e) => {
          console.log(e);
          self.overlay = false;
          this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });
    },
    selectStudent(student) {
      console.log(student);
    },
    deleteItem(item) {
      let self = this;
      self.$http
        .delete(`${self.$store.state.apiUrlFees}/receiptType/${item.idReceiptType}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          this.initialData();
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
