<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card color="alt" class="dialog-header">
        <v-toolbar dark color="darkBackground">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة استمارة جديدة</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-text-field color="accent" v-model="formNumber" label="رقم الاستمارة" :rules="studentRequiredFormRules" disabled></v-text-field>

          <v-text-field color="accent" v-model="studentName" label="اسم الطالب" :rules="studentRequiredFormRules"></v-text-field>

          <vuetify-money
            v-model="amount"
            v-bind:label="label"
            :rules="studentRequiredFormRules"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="disabled"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" color="accent" v-on:keyup.enter="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" icon small color="accent" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة استمارة جديدة</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    formApplications: Array,
  },
  data() {
    return {
      studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
      show: false,
      studentName: "",

      amount: "25000",
      label: "المبلغ",
      placeholder: " ",
      readonly: false,
      disabled: false,
      outlined: false,
      clearable: true,
      valueWhenIsEmpty: "",
      options: {
        locale: "pt-BR",
        prefix: "IQ",
        suffix: "",
        length: 11,
        precision: 0,
      },
      formNumber: this.formApplications.length > 0 ? this.formApplications[0].formNumber + 1 : 1,
    };
  },

  methods: {
    saveData() {
      let self = this;

      let currentDate = new Date();
      let foundYear = self.$store.state.years.find((year) => year.year.split("-")[0] == currentDate.getFullYear());

      if (73) {
        if (self.amount && self.studentName) {
          self.$http
            .get(`${self.$store.state.apiUrlFees}/lastFormNumber/73`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            })
            .then((resLast) => {
              console.log(resLast);
              let formApplicationData = {
                studentName: self.studentName,
                amount: self.amount,
                studyYearId: 73,
                applicationDate: `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`,
                formNumber: resLast.data?.formNumber ? resLast.data.formNumber + 1 : 1,
                createdBy: self.$store.state.userData.idEmployee,
              };
              this.$http
                .post(
                  `${this.$store.state.apiUrlFees}/addFormApplication`,

                  formApplicationData,
                  {
                    headers: { Authorization: `Bearer ${self.$store.state.user}` },
                  }
                )
                .then((res) => {
                  this.show = false;
                  this.$toasted.success("تم اضافه البيانات");
                  this.$emit("reloadTask");
                })
                .catch((err) => {
                  this.$toasted.error(" حدث خطأ في البيانات");
                });
            })
            .catch((err) => {
              this.$toasted.error(" حدث خطأ في البيانات");
            });
        } else {
          this.$toasted.error("الرجاء ادخال ادخال جميع القيم");
        }
      } else {
        console.log(foundYear);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
