<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px; background: #40426f">
          <v-col cols="8">
            <h3 style="color: #fff">{{ $store.state.userData.employeeName }}</h3>
          </v-col>
          <v-col cols="2" style="color: #fff">0.1.20</v-col>
          <v-col cols="2" align="left">
            <v-btn class="mx-2" text color="accent" @click="logout()">
              <v-icon dark> mdi-close </v-icon>
              تسجيل خروج
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container" fluid v-if="$store.state.userData.roleId != 61">
      <v-card elevation="6" color="#40426f">
        <v-row style="margin: 10px; background: #40426f">
          <v-col cols="4" style="text-align: center" align-self="center">
            <div class="glass">
              <h3 style="font-size: 1em; color: #ffffff; margin-top: 20%">الرئيسي</h3>
              <h5 style="font-size: 2em; font-weight: lighter; color: #ffffff; margin: 25px">
                {{
                  totalMain.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h5>
            </div>
          </v-col>

          <v-col cols="4" style="text-align: center" align-self="center">
            <div class="glass">
              <h3 style="font-size: 1em; color: #ffffff; margin-top: 20%">الاستمارات</h3>
              <h5 style="font-size: 2em; font-weight: lighter; color: #ffffff; margin: 25px">
                {{
                  totalCertificate.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h5>
            </div>
          </v-col>

          <v-col cols="4" style="text-align: center" align-self="center">
            <div class="glass">
              <h3 style="font-size: 1em; color: #ffffff; margin-top: 20%">الهويات</h3>
              <h5 style="font-size: 2em; font-weight: lighter; color: #ffffff; margin: 25px">
                {{
                  totalIdentity.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h5>
            </div>
          </v-col>

          <!--          

          <v-col cols="4">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">هويات حجز مقعد</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalIdentityBoxReserved.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">اقساط حجز مقعد</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalMainBoxReserved.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col> -->
        </v-row>
        <div style="padding: 30px">
          <v-row align="center" justify="space-around">
            <v-btn tile color="success" @click="lockBox" :disabled="locked">
              <v-icon left> mdi-lock </v-icon>
              قفل الصندوق
            </v-btn>
          </v-row>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      locked: false,
      totalMain: 0,
      totalIdentity: 0,
      totalCertificate: 0,
      totalIdentityBoxReserved: 0,
      totalMainBoxReserved: 0,
      overlay: false,
    };
  },
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;

      self.overlay = true;
      let date = new Date();
      let fullDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      console.log(fullDate);

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlFees}/receiptsSearch?dates=${JSON.stringify([fullDate, fullDate])}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlFees}/expensesSearch?dates=${JSON.stringify([fullDate, fullDate])}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlFees}/boxAmountByDate/${fullDate}`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
        ])
        .then(
          self.$http.spread((receipts, expenses, box) => {
            let expensesMainBox = expenses.data.filter((expense) => expense.boxId != 2 && expense.boxId != 7 && expense.boxId != 9);
            let expensesCertificateBox = expenses.data.filter((expense) => expense.boxId == 7);
            let expensesIdentityBox = expenses.data.filter((expense) => expense.boxId == 2);
            let expensesIdentityBoxReserved = expenses.data.filter((expense) => expense.boxId == 9);
            let expensesMainBoxReserved = expenses.data.filter((expense) => expense.boxId == 10);

            let receiptMainBox = receipts.data.filter((receipt) => receipt.boxId != 2 && receipt.boxId != 7 && receipt.boxId != 9);
            let receiptCertificateBox = receipts.data.filter((receipt) => receipt.boxId == 7);
            let receiptIdentityBox = receipts.data.filter((receipt) => receipt.boxId == 2);
            let receiptIdentityBoxReserved = receipts.data.filter((receipt) => receipt.boxId == 9);
            let receiptMainBoxReserved = receipts.data.filter((receipt) => receipt.boxId == 10);
            console.log(self.$store.state.boxAmounts);
            let totalMainBoxReserved =
              receiptMainBoxReserved.reduce((n, { amount }) => n + amount, 0) +
              self.$store.state.boxAmounts.find((box) => box.boxId == 10).amount -
              expensesMainBoxReserved.reduce((n, { amount }) => n + amount, 0);

            let totalIdentityBoxReserved =
              receiptIdentityBoxReserved.reduce((n, { amount }) => n + amount, 0) +
              self.$store.state.boxAmounts.find((box) => box.boxId == 9).amount -
              expensesIdentityBoxReserved.reduce((n, { amount }) => n + amount, 0);

            let totalMain =
              receiptMainBox.reduce((n, { amount }) => n + amount, 0) + self.$store.state.boxAmounts.find((box) => box.boxId == 1).amount - expensesMainBox.reduce((n, { amount }) => n + amount, 0);
            let totalIdentity =
              receiptIdentityBox.reduce((n, { amount }) => n + amount, 0) +
              self.$store.state.boxAmounts.find((box) => box.boxId == 2).amount -
              expensesIdentityBox.reduce((n, { amount }) => n + amount, 0);
            let totalCertificate =
              receiptCertificateBox.reduce((n, { amount }) => n + amount, 0) +
              self.$store.state.boxAmounts.find((box) => box.boxId == 7).amount -
              expensesCertificateBox.reduce((n, { amount }) => n + amount, 0);
            console.log(expensesIdentityBox);
            self.totalCertificate = totalCertificate;
            self.totalIdentity = totalIdentity;
            self.totalMain = totalMain;
            self.totalMainBoxReserved = totalMainBoxReserved;
            self.totalIdentityBoxReserved = totalIdentityBoxReserved;
            self.overlay = false;
            console.log(box.data);

            self.locked = box.data.length > 0 ? true : false;
          })
        )
        .catch((e) => {
          console.log(e);
          self.overlay = false;
          // this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });
    },
    logout() {
      localStorage.removeItem("employee");
      location.reload();
    },
    lockBox() {
      let self = this;
      let date = new Date();
      let fullDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      let boxLock = [
        { amount: self.totalMain, date: fullDate, boxId: 1 },
        { amount: self.totalCertificate, date: fullDate, boxId: 7 },
        { amount: self.totalIdentity, date: fullDate, boxId: 2 },
        { amount: self.totalMainBoxReserved, date: fullDate, boxId: 10 },
        { amount: self.totalIdentityBoxReserved, date: fullDate, boxId: 9 },
      ];
      console.log(boxLock);
      self.$http
        .post(`${self.$store.state.apiUrlFees}/addMultiBoxAmount`, boxLock, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
        .then((res) => {
          console.log(res.data);
          self.initialData();
          this.$toasted.success("تم قفل الصندوق");
        })
        .catch((err) => {
          this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.glass {
  height: 200px;
  width: 320px;

  margin: auto;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.175) 0%, rgba(255, 255, 255, 0.37) 100%) !important;
  /* background: rgba(255, 255, 255, 0.27); */
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.8px);
  -webkit-backdrop-filter: blur(3.8px);
  border: 1px solid rgba(255, 255, 255, 1);
}
</style>
