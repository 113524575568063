<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container class="container" fluid>
      <v-card color="#40426f" elevation="0">
        <v-card-title class="accent-color">
          <v-row>
            <v-col cols="4" style="line-height: 50px">
              <h4 class="white-text" style="vertical-align: middle">صفحة اقساط الاقسام</h4>
            </v-col>
            <v-col cols="4" style="line-height: 50px">
              <v-text-field v-model="search" color="accent" @change="searchStudent()" v-on:keyup.enter="searchStudent()" append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
            </v-col>
            <v-col cols="4" style="line-height: 50px; text-align: left">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mx-2" icon small color="accent" @click="exportExcel" v-bind="attrs" v-on="on">
                    <v-icon dark> mdi-microsoft-excel </v-icon>
                  </v-btn>
                </template>
                <span>تصدير</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table :headers="headers" :items="sectionLevels" :items-per-page="15" class="table-content table-warning-color">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>

          <template v-slot:[`item.level`]="{ item }">
            <p style="padding: 0 3px">{{ item.sectionLevel | setLevel }}</p>
          </template>

          <template v-slot:[`item.students`]="{ item }">
            <p style="padding: 0 3px">
              {{ getStudentLevelBySectionCount(item) }}
            </p>
          </template>

          <template v-slot:[`item.totalAmount`]="{ item }">
            <p style="padding: 0 3px">
              {{
                getStudentLevelBySection(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })
              }}
            </p>
          </template>

          <template v-slot:[`item.PaidAmount`]="{ item }">
            <p style="padding: 0 3px">
              {{
                getPaidStudentLevelBySection(item) > 0
                  ? getPaidStudentLevelBySection(item).toLocaleString("en-US", {
                      style: "currency",
                      currency: "IQD",
                    })
                  : getPaidStudentLevelBySection(item)
              }}
            </p>
          </template>

          <template v-slot:[`item.percentage`]="{ item }">
            <p style="padding: 0 3px">
              {{
                isNaN(Math.ceil((getPaidStudentLevelBySection(item) / getStudentLevelBySection(item)) * 100))
                  ? 0
                  : Math.ceil((getPaidStudentLevelBySection(item) / getStudentLevelBySection(item)) * 100)
              }}%
            </p>
          </template>

          <template v-slot:[`item.RemainAmount`]="{ item }">
            <p style="padding: 0 3px">
              {{
                (getStudentLevelBySection(item) - getPaidStudentLevelBySection(item)).toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })
              }}
            </p>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AddSectionCost from "../components/AddSectionCost.vue";
import EditSectionInstallments from "../components/EditSectionInstallments.vue";
const Excel = require("exceljs");
export default {
  components: {
    AddSectionCost,
    EditSectionInstallments,
  },
  data: () => ({
    search: "",
    overlay: false,
    selected: [],
    sectionAmounts: null,
    sectionLevels: [],
    sectionInstallments: [],
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "القسم", value: "sectionName" },
      { text: "المرحلة", value: "level" },
      { text: "عدد الطلبة", value: "students" },
      { text: "القسط الكلي", value: "totalAmount" },
      { text: "القسط المدفوع", value: "PaidAmount" },
      { text: "القسط المتبقي", value: "RemainAmount" },
      { text: "النسبة", value: "percentage" },
    ],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      self.overlay = true;
      self.$http
        .get(`${self.$store.state.apiUrlFees}/studentPaymentsLevelsAndSections`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          let levelsForSections = self.$store.state.sections.map((section) => {
            if (section.idSection == 7) {
              let sectionL = [
                { idSection: section.idSection, sectionName: section.sectionName, sectionLevel: 1 },
                { idSection: section.idSection, sectionName: section.sectionName, sectionLevel: 2 },
                { idSection: section.idSection, sectionName: section.sectionName, sectionLevel: 3 },
                { idSection: section.idSection, sectionName: section.sectionName, sectionLevel: 4 },
                { idSection: section.idSection, sectionName: section.sectionName, sectionLevel: 5 },
              ];

              return [...sectionL];
            } else {
              let sectionR = [
                { idSection: section.idSection, sectionName: section.sectionName, sectionLevel: 1 },
                { idSection: section.idSection, sectionName: section.sectionName, sectionLevel: 2 },
                { idSection: section.idSection, sectionName: section.sectionName, sectionLevel: 3 },
                { idSection: section.idSection, sectionName: section.sectionName, sectionLevel: 4 },
              ];

              return [...sectionR];
            }
          });
          var newArray = Array.prototype.concat.apply([], levelsForSections);
          self.sectionLevels = newArray;
          self.sectionAmounts = res.data;

          //   let sectionLevelOne = self.$store.state.sections.map((section) => {
          //     let totalAmountSectionOne = res.data.studentAmount.filter((student) => student.level == 1 && student.sectionId == section.idSection);
          //     return { [section.sectionName]: totalAmountSectionOne, sectionId: section.idSection };
          //   });

          //   let sectionLevelTwo = self.$store.state.sections.map((section) => {
          //     let totalAmountSectionOne = res.data.studentAmount.filter((student) => student.level == 2 && student.sectionId == section.idSection);
          //     return { [section.sectionName]: totalAmountSectionOne, sectionId: section.idSection };
          //   });

          //   let sectionLevelThree = self.$store.state.sections.map((section) => {
          //     let totalAmountSectionOne = res.data.studentAmount.filter((student) => student.level == 3 && student.sectionId == section.idSection);
          //     return { [section.sectionName]: totalAmountSectionOne, sectionId: section.idSection };
          //   });

          //   let sectionLevelFour = self.$store.state.sections.map((section) => {
          //     let totalAmountSectionOne = res.data.studentAmount.filter((student) => student.level == 4 && student.sectionId == section.idSection);
          //     return { [section.sectionName]: totalAmountSectionOne, sectionId: section.idSection };
          //   });

          //   let sectionLevelFive = self.$store.state.sections.map((section) => {
          //     let totalAmountSectionOne = res.data.studentAmount.filter((student) => student.level == 5 && student.sectionId == section.idSection);
          //     return { [section.sectionName]: totalAmountSectionOne, sectionId: section.idSection };
          //   });
          self.overlay = false;
        })
        .catch((e) => {
          console.log(e);
          self.overlay = false;
          this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });
    },
    selectStudent(student) {
      console.log(student);
    },
    getStudentLevelBySection(section) {
      let self = this;
      let totalAmountSectionOne = self.sectionAmounts.studentAmount.filter((student) => student.level == section.sectionLevel && student.sectionId == section.idSection);
      return totalAmountSectionOne.reduce((n, { discountAmount }) => n + discountAmount, 0) ? totalAmountSectionOne.reduce((n, { discountAmount }) => n + discountAmount, 0) : 0;
    },

    getStudentLevelBySectionCount(section) {
      let self = this;
      let totalAmountSectionOne = self.sectionAmounts.studentAmount.filter((student) => student.level == section.sectionLevel && student.sectionId == section.idSection);
      return totalAmountSectionOne.length;
    },

    getPaidStudentLevelBySection(section) {
      let self = this;
      if (section.sectionLevel == 1) {
        let paid = self.sectionAmounts.levelOneStudentPaid.filter((student) => student.level == section.sectionLevel && student.sectionId == section.idSection);
        return paid[0]?.studentPaid * 1 ? paid[0]?.studentPaid * 1 : 0;
      }

      if (section.sectionLevel == 2) {
        let paid = self.sectionAmounts.levelTwoStudentPaid.filter((student) => student.level == section.sectionLevel && student.sectionId == section.idSection);
        return paid[0]?.studentPaid * 1 ? paid[0]?.studentPaid * 1 : 0;
      }

      if (section.sectionLevel == 3) {
        let paid = self.sectionAmounts.levelThreeStudentPaid.filter((student) => student.level == section.sectionLevel && student.sectionId == section.idSection);
        return paid[0]?.studentPaid * 1 ? paid[0]?.studentPaid * 1 : 0;
      }

      if (section.sectionLevel == 4) {
        let paid = self.sectionAmounts.levelFourStudentPaid.filter((student) => student.level == section.sectionLevel && student.sectionId == section.idSection);
        return paid[0]?.studentPaid * 1 ? paid[0]?.studentPaid * 1 : 0;
      }

      if (section.sectionLevel == 5) {
        let paid = self.sectionAmounts.levelFiveStudentPaid.filter((student) => student.level == section.sectionLevel && student.sectionId == section.idSection);
        return paid[0]?.studentPaid * 1 ? paid[0]?.studentPaid * 1 : 0;
      }
    },

    exportExcel() {
      let self = this;
      const workbook = new Excel.Workbook();
      const worksheet = workbook.addWorksheet("احصائيات الاقسام المالية");

      worksheet.columns = [
        { header: "القسم", key: "sectionName", width: 50 },
        { header: "المرحلة", key: "level", width: 50 },
        { header: "عدد الطلبة", key: "students", width: 20 },
        { header: "القسط الكلي", key: "totalAmount", width: 50 },
        { header: "القسط المدفوع", key: "PaidAmount", width: 50 },
        { header: "القسط المتبقي", key: "RemainAmount", width: 50 },
        { header: "النسبة", key: "percentage", width: 50 },
      ];

      self.sectionLevels.forEach((section) => {
        worksheet.addRow({
          sectionName: section.sectionName,
          level: self.$options.filters.setLevel(section.sectionLevel),
          students: self.getStudentLevelBySectionCount(section),
          totalAmount: self.getStudentLevelBySection(section) > 0 ? self.getStudentLevelBySection(section) : 0,
          PaidAmount: self.getPaidStudentLevelBySection(section) > 0 ? self.getPaidStudentLevelBySection(section) : 0,
          RemainAmount:
            self.getStudentLevelBySection(section) > 0 ? self.getStudentLevelBySection(section) : 0 - self.getPaidStudentLevelBySection(section) > 0 ? self.getPaidStudentLevelBySection(section) : 0,
          percentage: `${
            isNaN(Math.ceil((self.getPaidStudentLevelBySection(section) / self.getStudentLevelBySection(section)) * 100))
              ? 0
              : Math.ceil((self.getPaidStudentLevelBySection(section) / self.getStudentLevelBySection(section)) * 100)
          }%`,
        });
      });

      worksheet.eachRow(function (row, rowNumber) {
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: "middle", horizontal: "center" };
        cell.fill = {
          type: "pattern",
          pattern: "darkTrellis",
          fgColor: { argb: "FFFFFF00" },
          bgColor: { argb: "FF0000FF" },
        };
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = `sectionPayments.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      }
    },
    setStudyType: function (studyType) {
      if (studyType == 1) {
        return "صباحي";
      } else {
        return "مسائي";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
