<template>
  <div>
    <v-dialog v-model="show" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-overlay :value="overlay" :absolute="true">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card color="alt" class="dialog-header">
        <v-toolbar dark color="darkBackground">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة سند قبض </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-autocomplete
            v-model="isInternal"
            label="نوع القبض"
            item-text="name"
            color="accent"
            item-value="id"
            :rules="studentRequiredFormRules"
            :items="isInternalType"
            @change="getByIsInternal()"
            item-color="accent"
          ></v-autocomplete>

          <v-autocomplete
            v-model="box"
            color="accent"
            label="أيداع المبالغ في صندوق"
            item-text="boxName"
            item-value="idBox"
            :rules="studentRequiredFormRules"
            :items="boxes"
            item-color="accent"
            @change="checkForPaymentType()"
          ></v-autocomplete>

          <v-autocomplete
            v-if="showSecondOptions"
            v-model="secondOptionSelected"
            label="خيار القبض الثانوي"
            item-text="name"
            item-value="id"
            color="accent"
            :rules="studentRequiredFormRules"
            :items="secondOptions"
            item-color="accent"
            @change="getBySecondOption()"
          ></v-autocomplete>

          <v-autocomplete
            v-if="isInternalTypeChosen"
            v-model="receiptTypeId"
            :disabled="isDisabled"
            label="من"
            color="accent"
            item-text="employeeName"
            item-value="idEmployee"
            :rules="studentRequiredFormRules"
            :items="internalFromOptions"
            item-color="accent"
            @change="getByCreatedBy()"
          ></v-autocomplete>

          <v-autocomplete
            v-if="!isInternalTypeChosen"
            v-model="receiptTypeId"
            :disabled="isDisabled"
            label="من"
            color="accent"
            item-text="typeName"
            item-value="idReceiptType"
            :rules="studentRequiredFormRules"
            :items="fromOptions"
            item-color="accent"
          ></v-autocomplete>

          <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field color="accent" v-model="date" label="تاريخ السند" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
          </v-menu>

          <vuetify-money
            v-model="amount"
            v-bind:label="label"
            :rules="studentRequiredFormRules"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="disabled"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
          />

          <v-textarea v-model="notes" color="accent" name="input-7-4" label="الملاحظات"></v-textarea>
        </v-card-text>

        <v-divider></v-divider>
        <v-container class="container" fluid v-if="showTable">
          <v-card elevation="6">
            <v-data-table :headers="headers" :items="employeeVouchers" item-key="idStudentFees" class="table-content component-table">
              <template v-slot:[`item.index`]="{ index }">
                <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
              </template>

              <template v-slot:[`item.level`]="{ item }">
                <p style="padding: 0 3px">{{ item.level | setLevel }}</p>
              </template>

              <template v-slot:[`item.studyType`]="{ item }">
                <p style="padding: 0 3px">{{ item.studyType | setStudyType }}</p>
              </template>

              <template v-slot:[`item.amount`]="{ item }">
                <p style="padding: 0 3px">
                  {{
                    item.amount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "IQD",
                    })
                  }}
                </p>
              </template>
            </v-data-table>
          </v-card>
        </v-container>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text color="accent" @click="saveData" v-on:keyup.enter="saveData"> قبض </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" icon small color="accent" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة سند قبض</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    boxes: Array,
  },
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,
    isInternal: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 10),
    menu2: false,
    isInternalType: [
      { name: "قبض داخلي", id: 1 },
      { name: "قبض خارجي", id: 2 },
    ],
    secondOptions: [
      { name: "اقساط", id: 1 },
      { name: "تأييدات ووثائق", id: 4 },
      { name: "صحة صدور", id: 5 },
      { name: "سنوات سابقة", id: 8 },
      { name: "اقساط + صحة صدور", id: 20 },
    ],
    showSecondOptions: false,
    secondOptionSelected: "",
    showTable: false,
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "الرقم الجامعي", value: "collegeNumber" },
      { text: "تاريخ التسديد", value: "paymentDateFormat" },
      { text: "عن", value: "typeName" },
      { text: "المبلغ", value: "amount" },
    ],

    box: "",
    overlay: false,
    isInternalTypeChosen: true,
    isDisabled: true,
    fromOptions: [],
    internalFromOptions: [],
    employeeVouchers: [],
    receiptTypeId: "",

    amount: "0",
    notes: "لايوجد",
    label: " المبلغ",
    placeholder: " ",
    readonly: false,
    disabled: true,
    outlined: false,
    clearable: true,
    valueWhenIsEmpty: "",
    options: {
      locale: "pt-BR",
      prefix: "IQ",
      suffix: "",
      length: 11,
      precision: 0,
    },
  }),

  methods: {
    saveData() {
      let self = this;

      let currentDate = new Date();
      let foundYear = self.$store.state.years.find((year) => year.year.split("-")[0] == currentDate.getFullYear());
      let receiptTypeIdCheck = self.isInternal == 1 ? 12 : self.receiptTypeId;
      let studentPaymentIds = [];
      if (self.isInternal == 1 && self.employeeVouchers.length == 0) {
        this.$toasted.error("لايوجد بيانات متاحة للقبض");
        return;
      }

      let boxIdAfter = self.box == 20 ? 1 : self.box;

      // if (self.isInternal != 1 && self.employeeVouchers.length == 0) {
      //   this.$toasted.error("لايوجد بيانات متاحة للقبض");
      //   return;
      // }

      if (self.isInternal == 1 && self.employeeVouchers.length > 0) {
        studentPaymentIds = self.employeeVouchers.map((voucher) => {
          return voucher.idStudentFees;
        });
      }

      if (73) {
        if (self.notes && self.box) {
          let receipt = {
            isInternal: self.isInternal,
            amount: self.amount == 1 ? self.employeeVouchers.reduce((n, { amount }) => n + amount, 0) : self.amount,
            notes: self.notes,
            receiptTypeId: receiptTypeIdCheck,
            receiptDate: self.date,
            studyYearId: 73,
            createdBy: self.$store.state.userData.idEmployee,
            boxId: boxIdAfter,
            studentPaymentIds: self.isInternal == 1 ? studentPaymentIds : [],
            employeeId: self.isInternalTypeChosen ? self.receiptTypeId : self.$store.state.userData.idEmployee,
          };
          console.log(receipt);

          this.$http
            .post(`${this.$store.state.apiUrlFees}/AddReceipt`, receipt, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            })
            .then((res) => {
              this.show = false;
              this.$toasted.success("تم اضافه البيانات");
              this.$emit("reloadTask");
            })
            .catch((err) => {
              this.$toasted.error(" حدث خطأ في البيانات");
            });
        } else {
          this.$toasted.error("الرجاء ادخال ادخال جميع القيم");
        }
      } else {
        console.log(foundYear);
      }
    },
    checkForPaymentType() {
      if (this.box == 1 && this.isInternal == 1) {
        this.showTable = false;
        this.showSecondOptions = true;
      } else if (this.isInternalTypeChosen) {
        this.showSecondOptions = false;
        this.getByCreatedBy();
      }
    },
    getBySecondOption() {
      let self = this;
      self.box = self.secondOptionSelected;
      this.getByCreatedBy();
    },
    getByCreatedBy() {
      let self = this;
      self.overlay = true;
      if (self.box) {
        if (self.receiptTypeId) {
          self.$http
            .get(`${self.$store.state.apiUrlFees}/receiptByCreatedBy?createdBy=${this.receiptTypeId}&studyYearId=73`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            })
            .then((res) => {
              // let boxId = self.box != 1 ? self.box : self.secondOptionSelected;
              // boxId = self.secondOptionSelected == 20 ? 1 : self.secondOptionSelected;
              console.log(self.box);
              let studentPayments = self.extractReceiptByBoxId(self.getBoxId(), res.data);
              self.employeeVouchers = studentPayments;
              console.log(self.employeeVouchers);
              self.amount = studentPayments.reduce((n, { amount }) => n + amount, 0);
              self.overlay = false;
              self.isDisabled = false;
              self.showTable = true;
            })
            .catch((e) => {
              console.log(e);
              self.overlay = false;
              this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
            });
        } else {
          self.overlay = false;
        }
      } else {
        self.overlay = false;
        this.$toasted.error("الرجاء اختيار الايداع في الصندوق اولا");
      }
    },
    getBoxId() {
      let self = this;
      if (self.box == 1) {
        if (self.secondOptionSelected != 20) {
          return self.secondOptionSelected;
        } else {
          return 1;
        }
      } else {
        return self.box;
      }
    },
    extractReceiptByBoxId(boxId, arrayOfPayments) {
      console.log(boxId);
      if (boxId == 1) {
        let studentPayments = arrayOfPayments.filter((pay) => {
          if (pay.paymentTypeId < 10 || pay.paymentTypeId == 33) {
            return pay;
          }
        });

        return studentPayments;
      } else if (boxId == 2) {
        let studentPayments = arrayOfPayments.filter((pay) => {
          if (pay.paymentTypeId == 24) {
            return pay;
          }
        });

        return studentPayments;
      } else if (boxId == 5) {
        let studentPayments = arrayOfPayments.filter((pay) => {
          if (pay.paymentTypeId == 31) {
            return pay;
          }
        });
        // Your App ID: ef559c74-26d7-42cd-82ba-fed33f4cfe94

        return studentPayments;
      } else if (boxId == 4) {
        let studentPayments = arrayOfPayments.filter((pay) => {
          if ((pay.paymentTypeId > 9 && pay.paymentTypeId < 25) || (pay.paymentTypeId > 25 && pay.paymentTypeId < 31) || pay.paymentTypeId == 25 || pay.paymentTypeId == 35) {
            return pay;
          }
        });

        return studentPayments;
      } else if (boxId == 8) {
        let studentPayments = arrayOfPayments.filter((pay) => {
          if (pay.paymentTypeId == 31) {
            return pay;
          }
        });

        return studentPayments;
      } else if (boxId == 20) {
        let studentPayments = arrayOfPayments.filter((pay) => {
          if (pay.paymentTypeId < 10 || pay.paymentTypeId == 33 || pay.paymentTypeId == 34) {
            return pay;
          }
        });

        return studentPayments;
      } else if (boxId == 9) {
        console.log(arrayOfPayments);
        let studentPayments = arrayOfPayments.filter((pay) => {
          if (pay.paymentTypeId == 24 && pay.isNewStudent == 1) {
            return pay;
          }
        });

        return studentPayments;
      } else if (boxId == 10) {
        console.log(arrayOfPayments);
        let studentPayments = arrayOfPayments.filter((pay) => {
          if (pay.paymentTypeId < 10 && pay.isNewStudent == 1) {
            return pay;
          }
        });

        return studentPayments;
      } else {
        let studentPayments = arrayOfPayments.filter((pay) => {
          if (pay.paymentTypeId == 34) {
            return pay;
          }
        });

        return studentPayments;
      }
    },
    getByIsInternal() {
      let self = this;
      self.overlay = true;
      self.showTable = false;
      if (self.isInternal == 1) {
        self.$http
          .get(`${self.$store.state.apiUrlFees}/receiptsByYearIdForReceipt/73`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            self.internalFromOptions = res.data.map((employee) => {
              return {
                idEmployee: employee.idEmployee,
                employeeName: `من صندوق ${employee.employeeName}`,
                boxAmount: employee.boxAmount,
              };
            });
            self.overlay = false;
            self.isDisabled = false;
            self.isInternalTypeChosen = true;
          })
          .catch((e) => {
            console.log(e);
            self.overlay = false;
            this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
          });
      } else {
        self.$http
          .get(`${self.$store.state.apiUrlFees}/receiptTypesWithoutNone`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            self.fromOptions = res.data;
            self.isDisabled = false;
            self.isInternalTypeChosen = false;
            self.overlay = false;
            self.disabled = false;
          })
          .catch((e) => {
            console.log(e);
            self.overlay = false;
            this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
          });
      }
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
