<template>
  <div>
    <v-container class="container" fluid>
      <v-card color="#40426f" elevation="0">
        <v-card-title class="accent-color">
          <v-row>
            <v-col cols="4" style="line-height: 50px">
              <h4 class="white-text" style="vertical-align: middle">ترحيل الطلبة</h4>
            </v-col>
            <v-col cols="4" style="line-height: 50px">
              <v-text-field v-model="search" color="accent" append-icon="mdi-magnify" @change="searchStudent()" v-on:keyup.enter="searchStudent()" label="بحث" single-line hide-details></v-text-field>
            </v-col>
            <v-col cols="4" style="line-height: 50px; text-align: left">
              <SearchStudentTransfer style="display: inline-block" @clicked="onSearch" />
              <StudentTransferComponent style="display: inline-block" :selectedStudents="selected" @reloadTask="removeData" v-if="selected.length > 0" />
              <StudentGraduation style="display: inline-block" :selectedStudents="selected" @reloadTask="removeData" v-if="selected.length > 0" />
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          show-select
          :items="students"
          v-model="selected"
          :items-per-page="1000"
          item-key="idStudent"
          class="table-content table-warning-color"
          :no-data-text="dataMessage"
        >
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>

          <template v-slot:[`item.level`]="{ item }">
            <p style="padding: 0 3px" v-if="item.studentStatusId == 3">متخرج</p>
            <p style="padding: 0 3px" v-else>
              {{ item.level | setLevel }}
            </p>
          </template>

          <template v-slot:[`item.studentName`]="{ item }">
            <h4 style="padding: 0 3px">{{ item.studentName }}</h4>
          </template>

          <template v-slot:[`item.studyType`]="{ item }">
            <p style="padding: 0 3px">
              {{ item.studyType | setStudyType }}
            </p>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <EditStudent :student="item" @reloadTask="searchStudent()" />
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import SearchStudentTransfer from "../components/SearchStudentTransfer.vue";
import StudentTransferComponent from "../components/StudentTransferComponent.vue";
import StudentGraduation from "../components/StudentGraduation.vue";
import EditStudent from "../components/EditStudent.vue";
export default {
  components: {
    SearchStudentTransfer,
    StudentTransferComponent,
    StudentGraduation,
    EditStudent,
  },
  data: () => ({
    search: "",
    selected: [],
    students: [],
    dataMessage: "الرجاء البحث عن طلبة",
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "اسم الطالب", value: "studentName" },
      { text: "القسم", value: "sectionName" },
      { text: "المرحلة", value: "level" },
      { text: "حالة الطالب", value: "statusName" },
      { text: "الرقم الجامعي", value: "collegeNumber" },
      { text: "جنس الطالب", value: "gender" },
      {
        text: "نوع الدراسة",
        value: "studyType",
      },
      { text: "الاجراءات", value: "actions" },
    ],
  }),
  created() {},
  methods: {
    onSearch(value) {
      let self = this;
      if (value.length > 0) {
        console.log(value);
        self.students = value;
      } else {
        self.dataMessage = "لايوجد طلبة في القسم";
      }
    },
    searchStudent() {
      let self = this;

      console.log(this.search);
      if (self.search) {
        self.$http
          .get(`${self.$store.state.apiUrlRegistration}/getStudentsBySearchBox/${self.search}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            self.overlay = false;
            self.students = res.data;
            console.log(res.data);
          })
          .catch((e) => {
            console.log(e);
            self.overlay = false;
          });
      }
    },
    addFess() {
      console.log(this.selected);
      if (this.selected.length > 0) {
        this.$store.state.studentPay = this.selected[0];
        this.$router.push("/fees").catch((error) => {});
      } else {
        this.$toasted.error("الرجاء اختيار طالب ");
      }
    },
    selectStudent(student) {
      let self = this;
      self.$store.state.studentDetails = student;
      this.$router.push("/details").catch((error) => {});
      console.log(student);
    },
    removeData() {
      let self = this;

      self.selected = [];
      self.students = [];
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      }
    },
    setStudyType: function (studyType) {
      if (studyType == 1) {
        return "صباحي";
      } else {
        return "مسائي";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
