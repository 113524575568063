<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container class="container" fluid>
      <v-card color="#40426f" elevation="0">
        <v-card-title class="accent-color">
          <v-row>
            <v-col cols="4" style="line-height: 50px">
              <h4 class="white-text" style="vertical-align: middle">صفحة الاستمارات</h4>
            </v-col>
            <v-col cols="4" style="line-height: 50px; text-align: center">
              <h5 style="color: #fff">عدد الاستمارات اليومي : {{ formApplicationCounter }} استمارة</h5>
            </v-col>
            <v-col cols="4" style="line-height: 50px; text-align: left">
              <AddForm style="display: inline-block" :formApplications="formApplication" @reloadTask="initialData" v-if="!overlay" />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table :headers="headers" :items="formApplication" :items-per-page="15" class="table-content table-warning-color">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>

          <template v-slot:[`item.level`]="{ item }">
            <p style="padding: 0 3px">{{ item.level | setLevel }}</p>
          </template>

          <template v-slot:[`item.studyType`]="{ item }">
            <p style="padding: 0 3px">{{ item.studyType | setStudyType }}</p>
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            <p style="padding: 0 3px">
              {{
                item.amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })
              }}
            </p>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <EditForm :formApplication="item" style="display: inline-block" @reloadTask="initialData()" />

            <DeleteDialog :dataToDelete="item" :url="`${$store.state.apiUrlFees}/formApplication/${item.idFormApplication}`" style="display: inline-block" @reloadTask="initialData()" />
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AddForm from "./../components/AddForm.vue";
import EditForm from "./../components/EditForm.vue";
import DeleteDialog from "./../components/DeleteDialog.vue";
export default {
  components: {
    AddForm,
    EditForm,
    DeleteDialog,
  },
  data: () => ({
    search: "",
    formApplicationCounter: 0,
    overlay: false,
    selected: [],
    formApplication: [],
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "اسم الطالب", value: "studentName" },
      { text: "رقم الاستمارة", value: "formNumber" },
      { text: "تاريخ الاستمارة", value: "applicationDateFormat" },
      { text: "المبلغ", value: "amount" },
      { text: "الاجراءات", value: "actions" },
    ],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      self.overlay = true;
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlFees}/formApplicationsToday`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlFees}/formApplicationsByYearId/73`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((todayApplication, allApplications) => {
            self.formApplication = allApplications.data;
            self.formApplicationCounter = todayApplication.data.length;
            self.overlay = false;
          })
        )
        .catch((err) => {
          console.log(err);
          self.overlay = false;
          this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });
    },
    selectStudent(student) {
      console.log(student);
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      }
    },
    setStudyType: function (studyType) {
      if (studyType == 1) {
        return "صباحي";
      } else {
        return "مسائي";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
