<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card color="alt" class="dialog-header">
        <v-toolbar dark color="darkBackground">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>تعديل الرقم الجامعي</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-text-field color="accent" v-model="student.collegeNumber" label="الرقم الجامعي" :rules="studentRequiredFormRules"></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text color="accent" @click="saveData" v-on:keyup.enter="saveData"> تعديل </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon class="mx-2" color="accent" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-pencil </v-icon>
        </v-btn>
      </template>
      <span>تعديل الرقم الجامعي</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    student: Object,
  },
  data() {
    return {
      studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
      show: false,
      studentName: "",
    };
  },

  methods: {
    saveData() {
      let self = this;
      console.log(self.student.collegeNumber);
      this.$http
        .put(
          `${this.$store.state.apiUrlRegistration}/studentCollegeNumber/${self.student.idStudent}`,
          { collegeNumber: self.student.collegeNumber },
          {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }
        )
        .then((res) => {
          console.log(res.data);
          this.show = false;
          this.$toasted.success("تم تعديل البيانات");
          this.$emit("reloadTask");
        })
        .catch((err) => {
          this.$toasted.error(" حدث خطأ في البيانات");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
