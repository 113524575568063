<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card color="alt" class="dialog-header">
        <v-toolbar dark color="darkBackground">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة سند صرف </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-autocomplete
            v-model="expensesTypeId"
            color="accent"
            label="تبويب الصرف"
            item-text="typeName"
            item-value="idExpensesType"
            :rules="studentRequiredFormRules"
            :items="expensesTypes"
            item-color="accent"
          ></v-autocomplete>

          <v-autocomplete v-model="box" color="accent" label="سحب من" item-text="boxName" item-value="idBox" :rules="studentRequiredFormRules" :items="boxes" item-color="accent"></v-autocomplete>

          <vuetify-money
            v-model="amount"
            v-bind:label="label"
            color="accent"
            :rules="studentRequiredFormRules"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="disabled"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
          />

          <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field color="accent" v-model="date" label="تاريخ السند" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
          </v-menu>

          <v-textarea v-model="notes" color="accent" name="input-7-4" label="الملاحظات"></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="accent" text @click="saveData" v-on:keyup.enter="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" icon small color="accent" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة سند صرف</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    boxes: Array,
    expensesTypes: Array,
  },
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,
    expensesTypeId: "",
    box: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 10),
    menu2: false,
    amount: "0",
    notes: "لايوجد",
    label: " المبلغ",
    placeholder: " ",
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: true,
    valueWhenIsEmpty: "",
    options: {
      locale: "pt-BR",
      prefix: "IQ",
      suffix: "",
      length: 11,
      precision: 0,
    },
  }),

  methods: {
    saveData() {
      let self = this;

      let currentDate = new Date();
      let foundYear = self.$store.state.years.find((year) => year.year.split("-")[0] == currentDate.getFullYear());
      // expensesDate: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
      if (73) {
        if (self.expensesTypeId && self.amount && self.notes && self.box) {
          let expense = {
            expensesTypeId: self.expensesTypeId,
            amount: self.amount,
            notes: self.notes,
            expensesDate: self.date,
            studyYearId: 73,
            createdBy: self.$store.state.userData.idEmployee,
            boxId: self.box,
          };

          this.$http
            .post(
              `${this.$store.state.apiUrlFees}/AddExpenses`,

              expense,
              {
                headers: { Authorization: `Bearer ${self.$store.state.user}` },
              }
            )
            .then((res) => {
              this.show = false;
              this.$toasted.success("تم اضافه البيانات");
              this.$emit("reloadTask");
            })
            .catch((err) => {
              this.$toasted.error(" حدث خطأ في البيانات");
            });
        } else {
          this.$toasted.error("الرجاء ادخال ادخال جميع القيم");
        }
      } else {
        console.log(foundYear);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
