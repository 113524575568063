<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container class="container" fluid>
      <v-card color="#40426f" elevation="0">
        <v-card-title class="accent-color">
          <v-row>
            <v-col cols="4" style="line-height: 50px">
              <h4 class="white-text" style="vertical-align: middle">صفحة المقبوضات</h4>
            </v-col>
            <v-col cols="4" style="line-height: 50px">
              <v-text-field v-model="search" color="accent" append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
            </v-col>
            <v-col cols="4" style="line-height: 50px; text-align: left">
              <AddReceipt style="display: inline-block" :expensesTypes="expensesTypes" :boxes="boxes" @reloadTask="initialData" v-if="!overlay" />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table :headers="headers" :items="receipts" :items-per-page="15" class="table-content table-warning-color">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            <p style="padding: 0 3px">
              {{
                item.amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })
              }}
            </p>
          </template>

          <template v-slot:[`item.type`]="{ item }">
            <p style="padding: 0 3px">{{ item.isInternal == 1 ? "قبض داخلي" : item.receiptTypeName }}</p>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="printVoucher(item)">
              <v-icon color="primary"> mdi-printer </v-icon>
            </v-btn>
            <EditReceipt :receipt="item" style="display: inline-block" />
            <DeleteDialog :dataToDelete="item" :url="`${$store.state.apiUrlFees}/deleteReceipt`" style="display: inline-block" @reloadTask="initialData()" />
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <canvas id="barcode"></canvas>
  </div>
</template>

<script>
import AddReceipt from "../components/AddReceipt.vue";
import DeleteDialog from "../components/DeleteDialog.vue";
import EditReceipt from "../components/EditReceipt.vue";
import { jsPDF } from "jspdf";
var JsBarcode = require("jsbarcode");
import n2wordsAR from "n2words/lib/i18n/AR.mjs";

export default {
  components: { AddReceipt, DeleteDialog, EditReceipt },
  data: () => ({
    overlay: false,
    voucher: {
      amount: 350000,
      boxId: 7,
      boxName: "صندوق الاستمارات",
      contractDate: "2020-11-08T23:00:00.000Z",
      contractTypeId: 2,
      createdBy: 413,
      dob: "1978-11-10T23:00:00.000Z",
      employeeId: 413,
      employeeName: "عمر ابراهيم خليل",
      fromEmployee: "عمر ابراهيم خليل",
      gender: "ذكر",
      idBox: 7,
      idEmployee: 413,
      idReceipt: 3012,
      idReceiptType: 9,
      isInternal: 2,
      mail: "omar.ibrahem@turath.edu.iq",
      motherName: "0",
      nationality: "IRAQI",
      notes: "تم استلام مبلغ استمارات القبول للطلبة الجدد للعام الدراسي 2022-2023 وبعدد 14 استمارة",
      phone: "07702760211",
      receiptDate: "2022-10-27T22:00:00.000Z",
      receiptDateFormat: "28/10/2022",
      receiptTypeId: 9,
      receiptTypeName: "كاشير استمارات تسجيل المرحلة الاولى",
      roleId: 72,
      sectionId: 15,
      statusId: 1,
      studyYearId: 73,
      typeName: "كاشير استمارات تسجيل المرحلة الاولى",
    },
    search: "",
    receipts: [],
    boxes: [],
    expensesTypes: [],
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "رقم الوصل", value: "idReceipt" },
      { text: "تاريخ الوصل", value: "receiptDateFormat" },
      { text: "نوع السند", value: "type" },
      { text: "المبلغ", value: "amount" },
      { text: "الملاحظات", value: "notes" },
      { text: "من", value: "fromEmployee" },
      { text: "الصندوق", value: "boxName", width: 200 },
      { text: "الاجراءات", value: "actions" },
    ],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      self.overlay = true;
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlFees}/receiptsByYearId/73`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlFees}/boxesForReceipts`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlFees}/expensesTypes`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((receipts, boxes, expensesTypes) => {
            console.log(receipts.data);
            self.receipts = receipts.data.reverse();
            self.boxes = boxes.data;
            self.expensesTypes = expensesTypes.data;
            self.overlay = false;
          })
        )
        .catch((e) => {
          console.log(e);
          self.overlay = false;
          this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });
    },
    printVoucher(voucher) {
      let self = this;
      JsBarcode("#barcode", `${voucher.idReceipt}`, {
        width: 3,
        height: 30,
        displayValue: false,
      });
      let stylesHtml = "";
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      let image = require("../assets/logo.png");

      // Open the print window
      const WinPrint = window.open("", "", "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0");
      console.log(window);
      let numberWriting = n2wordsAR(voucher.amount, { lang: "ar" });
      let barcodeCanvas = document.getElementById("barcode");
      console.log(voucher);
      WinPrint.document.write(`<!DOCTYPE html>
      <!DOCTYPE html>
        <html lang="en">
        ${stylesHtml}

          <style>
          body ,html{ margin:0px !important}
          body{
            border:1px solid #eee !important;
          }
          @media print {
              @page {
                size: A5 landscape !important;
                margin: 0cm;
              }
              @page :left {
                margin: 0cm;
              }

              @page :right {
                margin: 0cm;
              }
              .not-printable {
                display: none !important;
              }
              body {
                -webkit-print-color-adjust: exact !important;
                height: 100% !important;
                width: 100% !important;
                padding: 0px !important;
                margin: 0px !important;
                color: #000;
                background-color: #fff !important;
                page-break-before: avoid;
                page-break-before: avoid;
              }
              .adjust-main {
                padding: 0px !important;
              }
              header nav,
              footer {
                display: none;
              }
              h3 {
                font-size: 11px !important;
              }
              h5 {
                font-size: 9px !important;
              }

              .center-print {
                background: #ffffff !important;
              }

              .image-print {
                width: 100px !important;
                height: 100px !important;
                text-align: center !important;
                margin: auto !important;
              }
              span {
                font-size: 10px !important;
                font-weight: bolder !important;
              }
              .center-print {
                text-align: center !important;
              }
            }
            img {
              padding: 5px;
            }
            .image-print {
              width: 80px !important;
              height: 80px !important;
              text-align: center !important;
              margin: auto !important;
            }
            span {
              font-size: 10px !important;
              font-weight: bolder !important;
            }

            .center-print {
              text-align: center !important;
              background: #ffffff !important;
            }
            .print-padding {
              padding: 5px !important;
            } 
          </style>
          <body >
            <div data-v-4a284cfa="" class="center-print" style="border: 2px solid rgb(37, 37, 37); margin-top: 5px;height:100vh">
              <div data-v-4a284cfa="">
                <div data-v-4a284cfa="" class="row print-padding">
                  <div data-v-4a284cfa="" class="col col-4">
                    <h3 data-v-4a284cfa="">رقم الوصل : ${voucher.idReceipt}</h3>
                    <h3 data-v-4a284cfa="">تاريخ الوصل : ${voucher.receiptDateFormat}</h3>
                  </div>
                  <div data-v-4a284cfa="" class="col col-4">
                    <div data-v-4a284cfa="" class="v-image v-responsive my-3 theme--light" style="height: 90px">
                      <div class="v-responsive__sizer" style="padding-bottom: 99.5851%"></div>
                      <div class="v-image__image v-image__image--contain" style="background-image: url('${image}'); background-position: center center"></div>
                      <div class="v-responsive__content" style="width: 241px"></div>
                    </div>
                  </div>
               
                </div>
                <div data-v-4a284cfa="">
                  <div data-v-4a284cfa="" class="row">
                    <div data-v-4a284cfa="" class="col col-4"></div>
                    <div data-v-4a284cfa="" class="col col-4">
                      <h3 data-v-4a284cfa="">كلية التجريب الاهلية / الحسابات</h3>
                      <h3 data-v-4a284cfa="">سند قبض</h3>
                      <div data-v-4a284cfa="">
                        <img src="${barcodeCanvas.toDataURL()}"/>
                        <div style="display: none">Show this if the rendering fails.</div>
                      </div>
                    </div>
                    <div data-v-4a284cfa="" class="col col-4"></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">انا الموقع ادناه</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">امين الصندوق</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">I Certify</h3></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <h3 data-v-4a284cfa="" style="padding: 10px">استلمت من  ة ${voucher.isInternal == 1 ? voucher.fromEmployee : voucher.receiptTypeName} </h3>
                 
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">مبلغا وقدره</h3></div>
                    <div data-v-4a284cfa="" class="col col-4">
                      <h3 data-v-4a284cfa="" style="display: inline-block; margin-left: 10px">${voucher.amount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "IQD",
                      })}</h3>
                      <h3 data-v-4a284cfa="" style="display: inline-block">${numberWriting} فقط لاغير</h3>
                    </div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">Amount Of</h3></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">وذلك عن</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">تسديد ${
                      voucher.idBox == 1 ? "اقساط" : voucher.idBox == 4 || voucher.idBox == 5 || voucher.idBox == 9 || voucher.idBox == 10 ? voucher.boxName : voucher.boxName.split("صندوق")[1]
                    }</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">For</h3></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">ملاحظات</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">${voucher.notes}</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">Notes</h3></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4">
                      <h3>اسم وتوقيع امين الصندوق</h3>
                      </div>
                    <div data-v-4a284cfa="" class="col col-4"></div>
                    <div data-v-4a284cfa="" class="col col-4" style="padding-bottom: 80px">
                      <h3 data-v-4a284cfa="">اسم وتوقيع الموظف المختص</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </body>
        </html>
        `);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
#print-invoice {
  display: none;
}
@media print {
  #print-invoice {
    bottom: 0px;
    position: relative;
    display: block;
  }
}
.print-padding {
  padding: 10px !important;
}
</style>
