<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card color="alt" class="dialog-header">
        <v-toolbar dark color="darkBackground">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>تعديل معلومات الوصل</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-autocomplete
            color="accent"
            v-model="studentPay.voucherSectionId"
            :items="$store.state.sections"
            :rules="studentRequiredFormRules"
            item-text="sectionName"
            item-value="idSection"
            outlined
            label="القسم"
          ></v-autocomplete>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text color="accent" @click="saveData" v-on:keyup.enter="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon @click="show = true" v-bind="attrs" v-on="on">
          <v-icon color="accent"> mdi-pencil </v-icon>
        </v-btn>
      </template>
      <span>تعديل معلومات الوصل</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    studentPay: Object,
  },
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,
  }),
  created() {
    console.log(this.studentPay);
  },
  methods: {
    saveData() {
      let self = this;
      let data = {
        collegeNumber: self.studentPay.collegeNumber,
        paymentTypeId: self.studentPay.paymentTypeId,
        studentLevel: self.studentPay.studentLevel,
        amount: self.studentPay.amount,
        paymentDate: self.studentPay.paymentDate,
        notes: self.studentPay.notes,
        sectionId: self.studentPay.voucherSectionId,
        studyYearId: self.studentPay.studyYearId,
        createdBy: self.studentPay.createdBy,
        isChecked: self.studentPay.isChecked,
        notValid: self.studentPay.notValid,
        isReceived: self.studentPay.isReceived,
        voucherNumber: self.studentPay.voucherNumber,
        isNewStudent: self.studentPay.isNewStudent,
      };
      this.$http
        .put(`${this.$store.state.apiUrlFees}/studentPaymentSection/${this.studentPay.idStudentFees}`, data, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res);
          this.show = false;
          this.$toasted.success("تم تعديل البيانات");
          this.$emit("reloadTask");
          //104019
        })
        .catch((err) => {
          this.$toasted.error(" حدث خطأ في البيانات");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
