<template>
  <div>
    <v-container class="container not-printable" fluid>
      <v-card style="background: #40426f" dark>
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>صفحة التقارير</h3>
          </v-col>
          <v-col cols="2" align="left">
            <SearchReport style="display: inline-block" @clicked="onSearch" :expensesTypes="expensesTypes" />
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="container" fluid>
      <v-card>
        <h3 v-if="typeId == 0" style="text-align: center; padding: 10px; color: #ffffff">{{ dataMessage }}</h3>
      </v-card>

      <ReceiptReportComponent :key="keyComponent" :receipt="reportData" :headers="headers" :dates="selected" :showExport="showExport" v-if="typeId == 1" />
      <ExpensesReportComponent :key="keyComponent" :receipt="reportData" :headers="headers" :dates="selected" :showExport="showExport" v-if="typeId == 2" />
      <DailyReportComponent :key="keyComponent" :data="dailyReport" :showExport="showExport" :dates="selected" v-if="typeId == 3" />
    </v-container>
  </div>
</template>

<script>
import SearchReport from "../components/SearchReport.vue";
import ReceiptReportComponent from "../components/ReceiptReportComponent.vue";
import ExpensesReportComponent from "../components/ExpensesReportComponent.vue";
import DailyReportComponent from "../components/DailyReportComponent.vue";
export default {
  components: {
    SearchReport,
    ReceiptReportComponent,
    ExpensesReportComponent,
    DailyReportComponent,
  },
  data: () => ({
    search: "",
    selected: [],
    reportData: [],
    keyComponent: 0,
    dataMessage: "لانشاء تقرير يرجى البحث من اعلى اليسار",
    headers: [],
    expensesTypes: [],
    showExport: false,
    typeId: 0,

    dailyReport: "",
  }),
  created() {
    this.initialization();
  },
  methods: {
    initialization() {
      let self = this;
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlFees}/expensesTypes`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((expensesTypes) => {
            console.log(expensesTypes);
            self.expensesTypes = expensesTypes.data;
          })
        )
        .catch((e) => console.log(e));
    },
    onSearch(value) {
      let self = this;
      self.reportData = [];
      self.keyComponent += 1;
      console.log(value);
      if (value.data.length > 0) {
        if (value.type == 3) {
          console.log(value);
          self.dailyReport = value;
          self.typeId = value.type;
          self.selected = value.dates;
        } else {
          self.reportData = value.data;
          self.headers = value.headers;
          self.showExport = true;
          self.typeId = value.type;
          self.selected = value.dates;
        }
      } else {
        self.reportData = [];
        self.headers = [];
        self.showExport = false;
        self.dataMessage = "لايوجد بيانات";
      }
    },

    getSheetName(index) {
      switch (index) {
        case 0:
          return "المقبوضات الخارجية";
        case 1:
          return "الاقساط";
        case 2:
          return "الهويات";
        case 3:
          return "الاستمارات";
        case 4:
          return "صحة الصدور";
        case 5:
          return "السنوات السابقة";
        case 6:
          return "التأييدات والوثائق";
      }
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
