<template>
  <div>
    <div style="text-align: center" class="not-printable">
      <v-btn @click="printReport()" tile color="primary">
        <v-icon left> mdi-printer </v-icon>
        طباعة
      </v-btn>
    </div>
    <v-container class="container" fluid>
      <div>
        <h3 style="text-align: center; color: #fff">من تاريخ {{ dates[0] }} الى تاريخ {{ dates[1] }}</h3>
      </div>
      <table style="width: 100%; border: 1px solid" class="table-data" border="1">
        <tr>
          <th>التسلسل</th>
          <th>رقم الوصل</th>
          <th>تاريخ الوصل</th>
          <th>من صندوق</th>
          <th colspan="2">نوع السند</th>
          <th>المبلغ</th>
          <th colspan="2">الملاحظات</th>
        </tr>
        <tr v-for="(item, index) in data" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item.idExpenses }}</td>
          <td>{{ item.expensesDateFormat }}</td>
          <td>{{ item.boxName }}</td>
          <td colspan="2">{{ item.typeName }}</td>
          <td>{{ item.amount }}</td>
          <td colspan="2">{{ item.notes }}</td>
        </tr>
      </table>
    </v-container>
    <div>
      <p style="font-size: 8px">
        المجموع :
        {{
          getTotalMoney(receipt).toLocaleString("en-US", {
            style: "currency",
            currency: "IQD",
          })
        }}
      </p>
    </div>

    <!-- <v-card>
      <v-tabs background-color="primary" center-active dark centered v-model="tab">
        <v-tab>الصندوق الرئيسي</v-tab>
        <v-tab>صندوق الهويات</v-tab>
        <v-tab>صندوق الاستمارات</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-model="tab">
          <v-container class="container" fluid>
            <v-card elevation="6">
              <v-card-title>
                <v-row>
                  <v-col cols="10">
                  </v-col>
                  <v-col cols="2" style="text-align: left" v-if="showExport">
                    <v-btn icon @click="exportExcel()">
                      <v-icon color="primary"> mdi-microsoft-excel </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-data-table :headers="headers" :items="arrayData[tab]" :items-per-page="10" item-key="idStudent" class="table-content">
                <template v-slot:[`item.index`]="{ index }">
                  <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
                </template>
                <template v-slot:[`item.gender`]="{ item }">
                  <h4 style="padding: 0 3px">
                    {{ item.gender ? "انثى" : "ذكر" }}
                  </h4>
                </template>

                <template v-slot:[`item.studyType`]="{ item }">
                  <h4 style="padding: 0 3px">
                    {{ item.studyType ? "مسائي" : "صباحي" }}
                  </h4>
                </template>
                <template v-slot:[`item.studentLevel`]="{ item }">
                  <h4 style="padding: 0 3px">
                    {{ item.studentLevel.level | setLevel }}
                  </h4>
                </template>

                <template v-slot:[`item.amount`]="{ item }">
                  <p style="padding: 0 3px">
                    {{
                      item.amount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "IQD",
                      })
                    }}
                  </p>
                </template>

                <template v-slot:[`item.type`]="{ item }">
                  <p style="padding: 0 3px">{{ item.isInternal == 1 ? "قبض داخلي" : item.typeName }}</p>
                </template>
              </v-data-table>
              <h3 style="padding: 10px">
                المجموع :
                {{
                  getTotalMoney(arrayData[tab]).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h3>
            </v-card>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card> -->
  </div>
</template>

<script>
const Excel = require("exceljs");

export default {
  props: {
    receipt: Array,
    headers: Array,
    showExport: Boolean,
    dates: Array,
  },
  data() {
    return {
      tab: null,
      arrayData: [],
      voucherIndex: 0,
      data: [],
    };
  },
  created() {
    let self = this;
    let mainBox = self.receipt.filter((data) => data.boxId == 1 || data.boxId == 4 || data.boxId == 5 || data.boxId == 8);
    let identityBox = self.receipt.filter((data) => data.boxId == 2);
    let certificateBox = self.receipt.filter((data) => data.boxId == 7);
    let newData = [mainBox, identityBox, certificateBox];
    console.log(newData);
    self.arrayData = newData;
    self.data = self.receipt.filter((data) => data.boxId != 9);
  },
  methods: {
    exportExcel() {
      let self = this;
      const workbook = new Excel.Workbook();

      let mainBox = self.receipt.filter((data) => data.boxId == 1 || data.boxId == 4 || data.boxId == 5 || data.boxId == 8);
      let identityBox = self.receipt.filter((data) => data.boxId == 2);
      let certificateBox = self.receipt.filter((data) => data.boxId == 7);
      let newData = [mainBox, identityBox, certificateBox];
      console.log(newData);

      newData.forEach((data, index) => {
        let name = self.getSheetName(index);
        const worksheet = workbook.addWorksheet(name);
        worksheet.views = [{ rightToLeft: true }];

        worksheet.columns = [
          { header: "رقم الوصل", key: "expensesNumber", width: 15 },
          { header: "تاريخ الوصل", key: "expensesDate", width: 50 },
          { header: "نوع السند", key: "type", width: 50 },
          { header: "المبلغ", key: "amount", width: 20 },
          { header: "الملاحظات", key: "notes", width: 50 },
          { header: "بواسطة", key: "createdBy", width: 50 },
        ];

        data.forEach((expenses) => {
          worksheet.addRow({
            expensesNumber: expenses.idExpenses,
            expensesDate: expenses.expensesDateFormat,
            type: expenses.typeName,
            amount: expenses.amount,
            notes: expenses.notes,
            createdBy: expenses.employeeName,
          });
        });

        worksheet.eachRow(function (row, rowNumber) {
          row.eachCell((cell) => {
            cell.alignment = { vertical: "middle", horizontal: "center" };
          });
        });

        worksheet.getRow(1).eachCell((cell) => {
          cell.font = { bold: true };
          cell.alignment = { vertical: "middle", horizontal: "center" };
          cell.fill = {
            type: "pattern",
            pattern: "darkTrellis",
            fgColor: { argb: "FFFFFF00" },
            bgColor: { argb: "FF0000FF" },
          };
        });
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = `expensesReport.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
    },
    printReport() {
      let self = this;
      const WinPrint = window.open("", "", "left=0,top=0,width=1000,height=900,toolbar=0,scrollbars=0,status=0");
      WinPrint.document.write(`<!DOCTYPE html>
        <!DOCTYPE html>
          
        <html lang="ar">
        <style>
         
        @media print {
          @page { margin: 0.5cm !important; }
          
          .pagebreak {
            clear: both;page-break-after: always;
          }
          table { page-break-inside:auto }
          tr    { page-break-inside:avoid; page-break-after:auto }
        }
          body {
            direction : rtl !important;
          }
          table {
            width : 98% !important;
          }
          tr,tr td,th{
            text-align : center !important;
            font-size : 12px !important;
            padding : 5px !important;
          }
        </style>
        <body>
              ${self.printTableData()}
              <h5 style="padding: 20px; font-size: 12px">
              المجموع الكلي :
              ${self.getTotalMoney(self.receipt).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })}
            </h5>
        </body>`);

      self.voucherIndex = 0;
      WinPrint.focus();
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },

    getTotalMoney(arrayOfData) {
      if (arrayOfData) {
        let total = arrayOfData.reduce((n, { amount }) => n + amount, 0);
        return total;
      } else {
        return 0;
      }
    },
    sliceArrayPrint(arrayOfData) {
      let begin = 0;
      let end = 18;
      let newArray = [];
      let numberOfIteration = Math.ceil(arrayOfData.length / 18);
      console.log(Math.ceil(arrayOfData.length / 18));

      for (let i = 0; i < numberOfIteration; i++) {
        newArray.push(arrayOfData.slice(begin, end * (i + 1)));
        begin += 18;
      }
      return newArray;
    },

    printTableData() {
      let self = this;
      let tableData = "";
      let filteredData = self.receipt.filter((rec) => rec.boxId != 9);
      let receiptData = filteredData;
      let dataPrint = self.sliceArrayPrint(receiptData.reverse());

      for (let i = 0; i < dataPrint.length; i++) {
        tableData += `
        <h4 style="text-align:center">${self.dates[0]} / ${self.dates[1]}</h4>
        <table border='1' style="padding:3px;margin: auto !important;">
            <tr>
                <th>التسلسل</th>
                <th>رقم الوصل</th>
                <th>تاريخ الوصل</th>
                <th>من الصندوق</th>
                <th>نوع السند</th>
                <th>المبلغ</th>
                <th colspan="4">الملاحظات</th>
              </tr>
              ${self.rowTable(dataPrint[i])}
          </table>
          
          <h5 style="padding: 10px; font-size: 12px">
              المجموع :
              ${self.getTotalMoney(dataPrint[i]).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })}
            </h5>
            ${i == dataPrint.length - 1 ? "" : '<div style=" clear: both;page-break-after: always;"></div>'}
            
          `;
      }
      return tableData;
    },
    rowTable(arrayOfData) {
      let self = this;
      let tdData = "";
      for (let j = 0; j < arrayOfData.length; j++) {
        self.voucherIndex += 1;
        tdData += `
              <tr>
                <td>${self.voucherIndex}</td>
                <td>${arrayOfData[j].idExpenses}</td>
                <td>${arrayOfData[j].expensesDateFormat}</td>
                <td>${arrayOfData[j].boxName}</td>
                <td>${arrayOfData[j].typeName}</td>
                
                <td style="font-size:12px">${arrayOfData[j].amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })}</td>
                <td  colspan="4"> ${arrayOfData[j].notes}</td>
              </tr>
            
          `;
      }
      return tdData;
    },
    getSheetName(index) {
      switch (index) {
        case 0:
          return "الصندوق الرئيسي";
        case 1:
          return "صندوق الهويات";
        case 2:
          return "صندوق الاستمارات";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
tr,
tr td {
  text-align: center !important;
  padding: 5px !important;
}
tr,
tr td {
  text-align: center !important;
  color: #fff !important;
}
</style>
