<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="searchDialog" persistent max-width="700px" width="700px">
      <v-card color="alt" class="dialog-header">
        <v-toolbar dark color="darkBackground">
          <v-btn icon dark @click="searchDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>بحث في الطلبة</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 20px"></div>

          <v-autocomplete color="accent" v-model="sectionId" :items="$store.state.sections" item-text="sectionName" item-value="idSection" label="القسم"></v-autocomplete>

          <v-autocomplete color="accent" v-model="level" :items="levels" item-text="levelName" item-value="level" label="المرحلة"></v-autocomplete>

          <v-autocomplete color="accent" v-model="paymentTypeId" :items="paymentTypes" item-text="typeName" item-value="idPaymentType" label="الدفعة"></v-autocomplete>

          <v-autocomplete color="accent" v-model="typeOfPayId" :items="typeOfPay" item-text="name" item-value="id" label="النوع"></v-autocomplete>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="accent" text @click="searchData" v-on:keyup.enter="searchData"> بحث </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" icon small color="accent" @click="searchDialog = true" v-bind="attrs" v-on="on">
          <v-icon> mdi-magnify </v-icon>
        </v-btn>
      </template>
      <span>بحث</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    paymentTypes: Array,
  },
  data: () => ({
    searchDialog: false,
    sectionId: "",
    typeOfPayId: "",
    paymentTypeId: "",
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    studentName: "",
    collegeNumber: "",
    overlay: false,
    typeOfPay: [
      { name: "المسددين", id: 1 },
      { name: "غير مسددين", id: 2 },
      { name: "الجميع", id: 3 },
    ],
    levels: [
      { levelName: "المرحلة الاولى", level: 1 },
      { levelName: "المرحلة الثانية", level: 2 },
      { levelName: "المرحلة الثالثة", level: 3 },
      { levelName: "المرحلة الرابعة", level: 4 },
      { levelName: "المرحلة الخامسة", level: 5 },
    ],
    level: "",
    allSectionInstallments: [],
  }),
  created() {},
  methods: {
    searchData() {
      let self = this;
      self.overlay = true;
      let query = "studentStatusId = 1";

      if (self.sectionId) {
        query += `&sectionId=${self.sectionId}`;
      }

      if (self.level) {
        query += `&level=${self.level}`;
      }
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlFees}/filterSectionInstallments?sectionId=${self.sectionId}&studyYearId=73`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/paidStudents?${query}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((sectionInstallments, students) => {
            console.log(sectionInstallments);

            self.allSectionInstallments = sectionInstallments.data;

            self.overlay = false;
            let studentPay = students.data.map((student) => {
              return {
                studentName: student.studentName,
                level: student.level,
                idStudent: student.idStudent,
                acceptedName: student.acceptedName,
                studyType: student.studyType,
                sectionName: student.sectionName,
                isPaid: self.studentPayment(student.payments) == "مسدد" ? true : false,
                paidPercentage: self.percentagePaidStudent(student.payments).paid,
                alreadyPaid: self.percentagePaidStudent(student.payments).alreadyPaid,
                discount: student.discount,
              };
            });
            console.log(students.data);
            let data = {
              paymentTypeId: self.paymentTypeId,
              students: self.typeOfPayId == 1 ? studentPay.filter((student) => student.isPaid == true) : self.typeOfPayId == 2 ? studentPay.filter((student) => student.isPaid == false) : studentPay,
            };
            this.$emit("clicked", data);
          })
        )
        .catch((e) => {
          console.log(e);
          self.overlay = false;
        });

      // self.$http
      //   .get(`${self.$store.state.apiUrlRegistration}/paidStudents?${query}`, {
      //     headers: { Authorization: `Bearer ${self.$store.state.user}` },
      //   })
      //   .then((res) => {
      //     self.overlay = false;
      //     let studentPay = res.data.map((student) => {
      //       return {
      //         studentName: student.studentName,
      //         level: student.level,
      //         idStudent: student.idStudent,
      //         acceptedName: student.acceptedName,
      //         studyType: student.studyType,
      //         sectionName: student.sectionName,
      //         isPaid: self.studentPayment(student.payments) == "مسدد" ? true : false,
      //         paidPercentage: self.percentagePaidStudent(student.payments),
      //       };
      //     });
      //     console.log(res.data);
      //     let data = {
      //       paymentTypeId: self.paymentTypeId,
      //       students: self.typeOfPayId == 1 ? studentPay.filter((student) => student.isPaid == true) : self.typeOfPayId == 2 ? studentPay.filter((student) => student.isPaid == false) : studentPay,
      //     };
      //     this.$emit("clicked", data);
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //     self.overlay = false;
      //   });
      this.searchDialog = false;
    },
    percentagePaidStudent(payments) {
      let self = this;
      let foundInstallment = self.allSectionInstallments.find((section) => section.sectionId == self.sectionId && section.level == self.level);
      if (payments && foundInstallment) {
        let allPaid = payments.filter(
          (pay) =>
            pay.paymentTypeId == 1 ||
            pay.paymentTypeId == 2 ||
            pay.paymentTypeId == 3 ||
            pay.paymentTypeId == 4 ||
            pay.paymentTypeId == 5 ||
            pay.paymentTypeId == 6 ||
            pay.paymentTypeId == 7 ||
            pay.paymentTypeId == 8 ||
            pay.paymentTypeId == 9
        );
        let paid = ((allPaid.reduce((n, { amount }) => n + amount, 0) * 1) / foundInstallment.amount) * 1 * 100;
        let alreadyPaid = allPaid.reduce((n, { amount }) => n + amount, 0) * 1;

        return {
          paid: Math.floor(paid),
          alreadyPaid: alreadyPaid,
        };
      } else {
        return {
          paid: 0,
          alreadyPaid: 0,
        };
      }
    },
    studentPayment(payments) {
      let self = this;
      if (payments) {
        if (self.paymentTypeId == 1) {
          let foundPaid = payments.filter((pay) => pay.paymentTypeId >= 1 && pay.paymentTypeId < 10);
          if (foundPaid.length > 0) {
            return "مسدد";
          } else {
            return "غير مسدد";
          }
        } else if (self.paymentTypeId == 2) {
          let foundPaid = payments.filter((pay) => pay.paymentTypeId >= 2 && pay.paymentTypeId < 10);
          if (foundPaid.length > 0) {
            return "مسدد";
          } else {
            return "غير مسدد";
          }
        } else if (self.paymentTypeId == 3) {
          let foundPaid = payments.filter((pay) => pay.paymentTypeId >= 3 && pay.paymentTypeId < 10);
          if (foundPaid.length > 0) {
            return "مسدد";
          } else {
            return "غير مسدد";
          }
        } else if (self.paymentTypeId == 4) {
          let foundPaid = payments.filter((pay) => pay.paymentTypeId >= 4 && pay.paymentTypeId < 10);
          if (foundPaid.length > 0) {
            return "مسدد";
          } else {
            return "غير مسدد";
          }
        } else if (self.paymentTypeId == 5) {
          let foundPaid = payments.filter((pay) => pay.paymentTypeId >= 5 && pay.paymentTypeId < 10);
          if (foundPaid.length > 0) {
            return "مسدد";
          } else {
            return "غير مسدد";
          }
        } else if (self.paymentTypeId == 6) {
          let foundPaid = payments.filter((pay) => pay.paymentTypeId >= 6 && pay.paymentTypeId < 10);
          if (foundPaid.length > 0) {
            return "مسدد";
          } else {
            return "غير مسدد";
          }
        } else if (self.paymentTypeId == 7) {
          let foundPaid = payments.filter((pay) => pay.paymentTypeId >= 7 && pay.paymentTypeId < 10);
          if (foundPaid.length > 0) {
            return "مسدد";
          } else {
            return "غير مسدد";
          }
        } else if (self.paymentTypeId == 8) {
          let foundPaid = payments.filter((pay) => pay.paymentTypeId >= 8 && pay.paymentTypeId < 10);
          if (foundPaid.length > 0) {
            return "مسدد";
          } else {
            return "غير مسدد";
          }
        }
      } else {
        return "غير مسدد";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
