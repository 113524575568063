<template>
  <div>
    <v-dialog v-model="show" persistent max-width="800px" width="800px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>تثبيت الطالب</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="padding: 10px">
            <h3>معلومات الطالب</h3>
            <div style="padding: 10px"></div>
            <v-divider></v-divider>
            <div style="margin-top: 10px"></div>
            <v-row>
              <v-col cols="6"> اسم الطالب : {{ $store.state.studentLevelOne.studentName }} </v-col>
              <v-col cols="6"> القسم : {{ $store.state.studentLevelOne.firstSectionName }}</v-col>
            </v-row>

            <v-row>
              <v-col cols="6"> ايميل الطالب : {{ $store.state.studentLevelOne.collegeNumber }}<span>@turath.edu.iq</span></v-col>
              <v-col cols="6"> تاريخ الميلاد : {{ $store.state.studentLevelOne.dob }} </v-col>
            </v-row>

            <v-row>
              <v-col cols="6"> الجنسية : عراقية </v-col>
              <v-col cols="6">الجنس : {{ $store.state.studentLevelOne.gender == "1" || $store.state.studentLevelOne.gender == "ذكر" ? "ذكر" : "انثى" }}</v-col>
            </v-row>

            <v-row>
              <v-col cols="6">الدراسة : {{ $store.state.studentLevelOne.firstStudyType }} </v-col>
              <v-col cols="6">الرقم الجامعي : {{ $store.state.studentLevelOne.collegeNumber }}</v-col>
            </v-row>

            <v-row>
              <v-col cols="6"> قناة القبول : {{ $store.state.studentLevelOne.acceptedName }} </v-col>
              <v-col cols="6">الملاحظات : {{ $store.state.studentLevelOne.notes }}</v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" v-on:keyup.enter="saveData"> تثبيت </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-account-check </v-icon>
          تثبيت الطالب
        </v-btn>
      </template>
      <span>تثبيت الطالب</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    acceptedTypes: Array,
  },
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,
    discount: "",
    acceptedName: "",
  }),
  created() {
    console.log(this.$store.state.studentLevelOne);
  },
  methods: {
    saveData() {
      let self = this;

      let newStudent = {
        studentName: self.$store.state.studentLevelOne.studentName,
        collegeNumber: self.$store.state.studentLevelOne.collegeNumber,
        englishName: "none",
        mail: `${self.$store.state.studentLevelOne.collegeNumber}@turath.edu.iq`,
        dob: self.$store.state.studentLevelOne.dob,
        sectionId: self.$store.state.sections.filter((section) => section.sectionName == self.$store.state.studentLevelOne.firstSectionName)[0].idSection,
        nationality: "عراقية",
        gender: self.$store.state.studentLevelOne.gender == "1" || self.$store.state.studentLevelOne.gender == "ذكر" ? "ذكر" : "انثى",
        studyType: self.$store.state.studentLevelOne.firstStudyType == "صباحي" ? 1 : 2,
        note: self.$store.state.studentLevelOne.notes,
        registerYearId: 73,
        studentStatusId: 4,
        acceptedTypeId: self.$store.state.studentLevelOne.idAcceptedType,
      };

      console.log(newStudent);

      this.$http
        .post(`${this.$store.state.apiUrlRegistration}/confirmStudent`, newStudent, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          this.show = false;
          this.$toasted.success("تم تثبيت الطالب البيانات");
          this.$emit("reloadTask");
        })
        .catch((err) => {
          this.$toasted.error(" حدث خطأ في البيانات");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
